import { EditableAccount } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { AccountData } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AccountService, StudyoAccountSettings, StudyoSettingsStore } from '../../services';

export interface ProfileEditViewModel {
  firstName: string;
  lastName: string;
  color: Color;
  readonly backgroundImage: string;
  backgroundOpacity: number;
  backgroundOnlyOnHeader: boolean;
  readonly hasChanges: boolean;
  readonly canSave: boolean;

  uploadFile(dataUrl: string, fileName?: string): Promise<void>;
  clearBackgroundImage(): void;
  dismiss(success: boolean): void;
  save(): Promise<void>;
}

export class AppProfileEditViewModel implements ProfileEditViewModel {
  private _data: AccountData;
  private _editableAccount: EditableAccount;
  private _preferences: StudyoAccountSettings;

  constructor(
    private _accountService: AccountService,
    settingsStore: StudyoSettingsStore,
    private _onSuccess: () => void,
    private _onCancel: () => void
  ) {
    makeObservable(this);
    this._data = _accountService.displayedAccountData;

    if (this._data.isImpersonating) {
      throw new Error('User should not be able to edit active profile while impersonating.');
    }

    this._editableAccount = new EditableAccount(this._data.account, false);
    this._preferences = settingsStore.getPreferences(this._data.accountId);
  }

  @computed
  get firstName(): string {
    return this._editableAccount.profile.privateFirstName || this._editableAccount.firstName;
  }

  set firstName(value: string) {
    const profile = this._editableAccount.getEditableProfile();
    profile.privateFirstName = value;

    // If only the private first name gets changed, we must make sure the private last name
    // is not empty either. This makes sure it's at least the original last name.
    profile.privateLastName = this.lastName;
  }

  @computed
  get lastName(): string {
    return this._editableAccount.profile.privateLastName || this._editableAccount.lastName;
  }

  set lastName(value: string) {
    const profile = this._editableAccount.getEditableProfile();
    profile.privateLastName = value;

    // If only the private first name gets changed, we must make sure the private first name
    // is not empty either. This makes sure it's at least the original first name.
    profile.privateFirstName = this.firstName;
  }

  @computed
  get color(): Color {
    return this._editableAccount.profile.avatarColor;
  }

  set color(value: Color) {
    this._editableAccount.getEditableProfile().avatarColor = value;
  }

  @computed
  get backgroundImage() {
    return this._editableAccount.profile.backgroundImageWeb;
  }

  @computed
  get backgroundOpacity() {
    return this._preferences.backgroundOpacity;
  }

  set backgroundOpacity(value: number) {
    this._preferences.backgroundOpacity = value;
  }

  @computed
  get backgroundOnlyOnHeader() {
    return this._preferences.backgroundOnlyOnHeader;
  }

  set backgroundOnlyOnHeader(value: boolean) {
    this._preferences.backgroundOnlyOnHeader = value;
  }

  @computed
  get hasChanges(): boolean {
    return this._editableAccount.hasChanges;
  }

  @computed
  get canSave(): boolean {
    return this.firstName.length > 0 && this.lastName.length > 0;
  }

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    const imageUrl = await this._accountService.uploadBackgroundImage(dataUrl, fileName ?? '');
    this._editableAccount.getEditableProfile().backgroundImageWeb = imageUrl;
  }

  clearBackgroundImage(): void {
    this._editableAccount.getEditableProfile().backgroundImageWeb = '';
  }

  dismiss(success: boolean) {
    if (success) {
      this._onSuccess();
    } else {
      this._onCancel();
    }
  }

  async save() {
    await this._data.createOrUpdateAccount(this._editableAccount);

    // I have not found an elegant way to prevent side effects when refreshing profile info
    // while preventing changes in demo mode.
    if (!this._data.isPreventingChanges) {
      await this._accountService.refreshAccounts();
      await this._accountService.setCurrentDisplayedAccount(this._editableAccount.id, true);
    }
  }
}
