import { NavigateFunctionAsync } from '@shared/utils';
import { AccountService } from '../services';

export interface LogoutViewModel {
  onInit(navigate: NavigateFunctionAsync): Promise<void>;
}

export class AppLogoutViewModel implements LogoutViewModel {
  constructor(private readonly _accountService: AccountService) {}

  async onInit(navigate: NavigateFunctionAsync) {
    try {
      await this._accountService.completeLogout();
      await navigate('/');
    } catch {
      // Nothing to do.
    }
  }
}
