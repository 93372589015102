import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { withTransparencyWhen } from '@studyo/theme';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PlannerSchoolDayHeaderViewModel } from '../../../viewmodels';
import { DayInfo } from '../utils';

export interface PlannerDayHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerSchoolDayHeaderViewModel;
}

export const PlannerDayHeader = observer((props: PlannerDayHeaderProps) => {
  const { localizationService } = useStudyoServices();
  const { hasBackgroundImage, backgroundOpacity } = useBackgroundImage();
  const { sx = [], className, viewModel } = props;
  const theme = useTheme();

  const { isToday } = viewModel;

  const dayOfWeekText = localizationService.localizedStrings.models.dayOfWeek.shortLocalizedDayOfWeek(
    viewModel.day.dayOfWeek
  );

  return (
    <Stack
      sx={{
        ...sx,
        backgroundColor: withTransparencyWhen(
          hasBackgroundImage,
          theme.studyo.agenda.planner.grid.dayHeaderBackgroundColor,
          backgroundOpacity
        ),
        position: 'relative',
        overflow: 'hidden'
      }}
      className={className}
    >
      {isToday && (
        <Box
          sx={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.15,
            backgroundColor: isToday ? theme.studyo.todaySymbolBackgroundColor : undefined
          }}
        />
      )}
      <DayInfo sx={{ zIndex: 2, flex: 1, width: '100%' }} day={viewModel.day} key={viewModel.day.asString}>
        <Stack
          sx={{
            flex: 1,
            p: 1,
            overflow: 'hidden'
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: 'baseline',
              overflow: 'hidden'
            }}
          >
            <Stack direction="row" spacing={0.5}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: '400'
                }}
              >
                {viewModel.day.day}
              </Typography>

              {viewModel.cycleDayTitle.length > 0 && (
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {viewModel.cycleDayTitle}
                </Typography>
              )}
            </Stack>

            <Typography
              variant="h6"
              noWrap
              sx={{
                fontWeight: '500'
              }}
            >
              {dayOfWeekText}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <SpecialDaySymbolGrid
              symbols={viewModel.specialDaySymbols}
              squareSize={viewModel.specialDaySymbols.length > 1 ? 10 : 22}
              displayKind={viewModel.specialDaySymbols.length > 1 ? 'grid' : 'linear'}
            />

            <Typography
              variant="caption"
              noWrap
              sx={{
                flex: 1
              }}
            >
              {viewModel.specialDaysTitle}
            </Typography>
          </Stack>
        </Stack>
      </DayInfo>
    </Stack>
  );
});
