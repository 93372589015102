import { SxProps } from '@mui/material';
import { useTrackWebPage } from '@studyo/services';
import { AgendaDailyView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface AgendaDailyScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AgendaDailyScreen = observer((props: AgendaDailyScreenProps) => {
  const { modalService, viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(() => viewModelFactory.createAgendaDaily(), [configId, accountId]);
  useTrackWebPage('Daily View');

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [configId, accountId]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (!modalService.isDisplayingModal) {
      if (e.code === 'ArrowLeft') {
        viewModel.goToPreviousPage();
      } else if (e.code === 'ArrowRight') {
        viewModel.goToNextPage();
      }
    }
  };

  return <AgendaDailyView viewModel={viewModel} sx={sx} className={className} />;
});
