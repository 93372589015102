import {
  AnalyticsConfigInfo,
  AnalyticsEvent,
  AnalyticsPage,
  AnalyticsService,
  AnalyticsUserInfo
} from '@shared/services/analytics';
import { Analytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';

export interface FirebaseAnalyticsEventProperties {
  eventCategory: string;
  eventLabel?: string;
}

export abstract class WebFirebaseAnalyticsService<
  TPage extends AnalyticsPage<string>,
  TEvent extends AnalyticsEvent<string>,
  TPageProperties extends object,
  TEventProperties extends FirebaseAnalyticsEventProperties,
  TErrorProperties extends object
> implements AnalyticsService<TPage, TEvent>
{
  protected _userInfo: AnalyticsUserInfo | undefined;
  protected _configInfo: AnalyticsConfigInfo | undefined;

  protected constructor(private readonly _firebaseAnalytics: Analytics) {}

  setUserInfo(info: AnalyticsUserInfo) {
    this._userInfo = info;
  }

  clearUserInfo() {
    this._userInfo = undefined;
  }

  setConfigInfo(info: AnalyticsConfigInfo) {
    this._configInfo = info;
  }

  clearConfigInfo() {
    this._configInfo = undefined;
  }

  trackPage(page: TPage): void {
    // Since the main goal of tracking with Firebase Analytics is to
    // feed data to Studyo Insights,don't track anonymous users.
    if (this._userInfo == null) {
      return;
    }

    // Build the properties to send with the event
    const properties = this.buildPageProperties(page);

    // Ensure the authenticated context is properly set
    this.ensureAuthenticatedUserContext();

    // Send the event to Firebase Analytics
    this.executeActionWithLogging(
      () => logEvent(this._firebaseAnalytics, 'page_view', { page_title: page.name, ...properties }),
      'Firebase Analytics - Tracking Page',
      () => console.log('Name:', page.name),
      properties
    );
  }

  trackEvent(event: TEvent): void {
    // Since the main goal of tracking with Firebase Analytics is to
    // feed data to Studyo Insights,don't track anonymous users.
    if (this._userInfo == null) {
      return;
    }

    // Build the properties to send with the event
    const properties = this.buildEventProperties(event);

    // Set the properties that are specific to events
    properties.eventCategory = event.action.category;
    properties.eventLabel = event.label;

    // Ensure the authenticated context is properly set
    this.ensureAuthenticatedUserContext();

    // Convert the event name to snake_case because that's what Firebase Analytics expects.
    const eventName = event.action.name.toLowerCase().replace(/\s+/g, '_');

    // Send the event to Firebase Analytics
    this.executeActionWithLogging(
      () => logEvent(this._firebaseAnalytics, eventName, properties),
      'Firebase Analytics - Tracking Event',
      () => {
        console.log('Action:', event.action.name);
        console.log('Category:', event.action.category);
        console.log('Label:', event.label);
      },
      properties
    );
  }

  protected abstract buildPageProperties(page: TPage): TPageProperties;
  protected abstract buildEventProperties(event: TEvent): TEventProperties;
  protected abstract buildErrorProperties(error: Error): TErrorProperties;

  private ensureAuthenticatedUserContext() {
    if (this._userInfo != null) {
      setUserId(this._firebaseAnalytics, this._userInfo.userId);
      setUserProperties(this._firebaseAnalytics, {
        configId: this._configInfo?.configId,
        accountId: this._configInfo?.accountId
      });
    } else {
      setUserId(this._firebaseAnalytics, null);
      setUserProperties(this._firebaseAnalytics, { configId: null, accountId: null });
    }
  }

  private executeActionWithLogging(
    actionCallback: () => unknown,
    logTitle: string,
    logInformationCallback?: () => void,
    actionProperties?: unknown
  ): void {
    try {
      console.group(logTitle);

      if (logInformationCallback != null) {
        logInformationCallback();
      }

      if (actionProperties) {
        console.log(`Custom properties:`, actionProperties);
      }

      actionCallback();

      console.log(`Successfully completed!`);
    } catch (error) {
      console.log(`Failed with error:`, error);
    } finally {
      console.groupEnd();
    }
  }
}
