import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors, SectionShadowColors } from '@shared/models/Colors';
import { Droppable, dragManager } from '@shared/rxp/drag-drop';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { PlannerCellViewModel } from '../../../viewmodels';
import { DisplayableContentBox, isDisplayableContentDragData } from '../../contents';
import { PeriodCellDragOverIndicator, PeriodCellDragOverType } from '../utils';
import { PlannerConstants } from './PlannerConstants';
import { PlannerPeriodHeader } from './PlannerPeriodHeader';

export interface PlannerPeriodCellProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerCellViewModel;
  hasTransparency?: boolean;
}

export const PlannerPeriodCell = observer((props: PlannerPeriodCellProps) => {
  const { viewModel, sx = [], className, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();
  const [dragState, setDragState] = useState<PeriodCellDragOverType | undefined>();

  let sectionColor = theme.studyo.periods.freePeriodColor;
  let headerBackgroundColor = theme.studyo.periods.freePeriodColor;
  let headerTextColor = theme.studyo.periods.freePeriodTextColor;
  let shadowColor = theme.studyo.periods.freePeriodShadowColor;

  if (viewModel.sectionColor != null) {
    sectionColor = SectionColors.get(viewModel.sectionColor)!;
    shadowColor = SectionShadowColors.get(viewModel.sectionColor)!;

    if (!viewModel.isSkipped) {
      headerBackgroundColor = SectionColors.get(viewModel.sectionColor)!;
      headerTextColor = theme.studyo.periods.periodWithSectionTextColor;
    }
  }

  const onDragOver = (data: unknown) => {
    if (isDisplayableContentDragData(data)) {
      setDragState(viewModel.compareSection(data.sectionId) ? 'move' : 'copy');
    }
  };

  const onDragLeave = () => {
    setDragState(undefined);
  };

  const onDrop = (data: unknown) => {
    if (isDisplayableContentDragData(data)) {
      dragManager.clearDragData();
      viewModel.copyContent(data.contentId, data.sectionId, data.isDraggingAssignment);
    }

    setDragState(undefined);
  };

  const renderPeriodTag = () => {
    return (
      <Typography
        sx={{
          color: sectionColor,
          fontWeight: '500'
        }}
      >
        {viewModel.periodTag ?? ''}
      </Typography>
    );
  };

  return (
    <Stack
      sx={{
        ...sx,
        backgroundColor: withTransparencyWhen(
          hasTransparency,
          theme.studyo.agenda.planner.grid.cellWithPeriodBackgroundColor,
          backgroundOpacity
        )
      }}
      className={className}
    >
      <PlannerPeriodHeader
        sx={{ height: PlannerConstants.periodHeaderHeight }}
        backgroundColor={withTransparencyWhen(hasTransparency, headerBackgroundColor, backgroundOpacity)}
        shadowColor={shadowColor}
        periodTitle={viewModel.title}
        numberOfPeriodRemainingInTerm={viewModel.remainingOccurrences}
        onPress={() => void viewModel.displayPeriodInfo()}
        textColor={headerTextColor}
      />
      <Droppable
        sx={{ flex: 1 }}
        handleDragOver={onDragOver}
        handleDragLeave={onDragLeave}
        handleDrop={onDrop}
        acceptedType="content"
      >
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <DisplayableContentBox
            sx={{ flex: 1 }}
            viewModel={viewModel.contentBox}
            iconSize={PlannerConstants.iconSize}
            itemSpacing={PlannerConstants.itemSpacing}
            minItemHeight={PlannerConstants.minItemHeight}
            minItemWidth={PlannerConstants.columnWidth}
            representationKind="detailed"
            accessoryView={viewModel.displayPeriodTag ? renderPeriodTag : undefined}
            canDragTasks={true}
          />

          {dragState != null && (
            <PeriodCellDragOverIndicator sx={{ position: 'absolute', bottom: 0, left: 0 }} type={dragState} />
          )}
        </Stack>
      </Droppable>
    </Stack>
  );
});
