import { Box, CardActionArea, Checkbox, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { DoubleClickView } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { withTransparencyWhen } from '@studyo/theme/ColorUtils.ts';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook.ts';
import { TimelineListElementMarginType, TimelineListItemViewModel, TimelineUtils } from '@studyo/viewmodels';
import { Observer, observer } from 'mobx-react-lite';
import { StudyoThemeService } from '../../../../services';
import { DisplayableContent } from '../../../contents';
import { TimelineListElementMarginForType } from './TimelineList';
import { PlannerListItemHeight, StateIndicatorViewWidth } from './TimelineListUtils.ts';

export interface TimelineListItemProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineListItemViewModel;
  marginType: TimelineListElementMarginType;
  themeService?: StudyoThemeService;
  hasTransparency?: boolean;
}

const ContentIconSize = 28;
const ContentIconContainerSize = ContentIconSize + 2;

export const TimelineListItem = observer(
  ({ sx = [], className, viewModel, marginType, hasTransparency = false }: TimelineListItemProps) => {
    const { backgroundOpacity } = useBackgroundImage();
    const theme = useTheme();

    const sectionColor = viewModel.color;
    const calculatedColor = withTransparencyWhen(
      hasTransparency,
      sectionColor != null ? SectionColors.get(sectionColor)! : theme.studyo.periods.freePeriodColor,
      backgroundOpacity
    );

    const textColor = viewModel.hasSection ? theme.studyo.agenda.timeline.courseTextColor : undefined;
    const stateColor = withTransparencyWhen(
      hasTransparency,
      TimelineUtils.getStateColor(viewModel.state, theme),
      backgroundOpacity
    );

    // Defaults to app text color.
    const stateTextColor =
      viewModel.state === 'late' ? theme.studyo.agenda.timeline.stateTextColor : theme.palette.text.primary;

    const onPress = () => {
      if (viewModel.isEditing) {
        viewModel.toggleSelection();
      } else {
        void viewModel.scrollToContentAndOpenTaskInfo();
      }
    };

    const onDoublePress = () => {
      if (!viewModel.isEditing) {
        viewModel.toggleContentState();
      }
    };

    const renderSelection = () => {
      return (
        <Observer>
          {() => (
            <Box
              sx={{
                pr: viewModel.isEditing ? 0 : 0.5
              }}
            >
              {viewModel.isEditing && (
                <Checkbox checked={viewModel.isSelected} disableFocusRipple disableTouchRipple disableRipple />
              )}
            </Box>
          )}
        </Observer>
      );
    };

    return (
      <CardActionArea
        className={className}
        sx={{
          ...sx,
          marginBottom: TimelineListElementMarginForType(marginType),
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          cursor: 'pointer',
          alignItems: 'center',
          position: 'relative'
        }}
        onClick={onPress}
      >
        {renderSelection()}

        <Stack
          direction="row"
          sx={{ flex: 1, height: PlannerListItemHeight, alignItems: 'center', overflow: 'hidden' }}
        >
          <DoubleClickView
            sx={{
              marginLeft: `${ContentIconContainerSize / 2}px`,
              width: 62,
              height: PlannerListItemHeight,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: calculatedColor,
              borderTopLeftRadius: 2,
              borderBottomLeftRadius: 2,
              display: 'flex',
              flexShrink: 0,
              color: textColor
            }}
            onPress={onPress}
            onDoublePress={onDoublePress}
            propagateEvent={false}
          >
            <Stack>
              <Typography
                variant="caption"
                noWrap
                sx={{
                  fontWeight: '600',
                  textAlign: 'center',
                  color: 'inherit'
                }}
              >
                {viewModel.sectionTitle}
              </Typography>
              <Typography variant="caption" noWrap sx={{ textAlign: 'center', color: 'inherit' }}>
                {viewModel.sectionNumber}
              </Typography>
            </Stack>
          </DoubleClickView>

          <Stack sx={{ height: PlannerListItemHeight, flex: 1, overflow: 'hidden' }}>
            <Stack
              sx={{
                flex: 1,
                justifyContent: 'center',
                px: 1,
                backgroundColor: theme.studyo.agenda.timeline.contentInfoContainerBackgroundColor
              }}
            >
              <Typography variant="body2" noWrap sx={{ fontWeight: '600' }}>
                {viewModel.title}
              </Typography>

              <Typography variant="caption" noWrap sx={{ color: (theme) => theme.palette.text.secondary }}>
                {viewModel.notes || <>&nbsp;</> /* Making sure that this label's height is not 0*/}
              </Typography>
            </Stack>

            {viewModel.isActive && <Box sx={{ height: '2px', flexShrink: 0, backgroundColor: stateColor }} />}
          </Stack>

          {viewModel.isActive && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: PlannerListItemHeight,
                width: StateIndicatorViewWidth,
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                backgroundColor: stateColor,
                color: stateTextColor
              }}
            >
              <Typography
                variant={viewModel.state === 'late' ? 'h6' : 'subtitle2'}
                sx={{
                  color: 'inherit',
                  ml: viewModel.state === 'late' ? '2px' : '1px',
                  textAlign: 'center'
                }}
              >
                {viewModel.stateText}
              </Typography>
            </Box>
          )}

          <DisplayableContent
            sx={{
              position: 'absolute',
              flexShrink: 0,
              height: ContentIconSize,
              width: ContentIconSize,
              borderRadius: ContentIconContainerSize / 2,
              padding: '1px',
              overflow: 'visible',
              backgroundColor: theme.studyo.agenda.timeline.iconContainerBackgroundColor
            }}
            viewModel={viewModel.displayableContentViewModel}
            representation="icon"
            iconSize={ContentIconSize}
            iconColor={theme.studyo.contents.icons.normalColor}
            tapHandler={onPress}
            doubleTapHandler={onDoublePress}
            showUnreadMarker
          />
        </Stack>
      </CardActionArea>
    );
  }
);
