import { Box, CardActionArea, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors, SectionShadowColors } from '@shared/models/Colors';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { PeriodsSectionListItemViewModel } from '../../../viewmodels';

export interface PeriodsSectionListItemProps {
  sx?: SxProps;
  className?: string;
  isSelected: boolean;
  viewModel: PeriodsSectionListItemViewModel;
  hasTransparency?: boolean;
}

export const PeriodsSectionListItem = observer((props: PeriodsSectionListItemProps) => {
  const { isSelected, sx = [], className, viewModel, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();
  const colors = theme.studyo.periods;
  const backgroundColor = withTransparencyWhen(
    hasTransparency,
    viewModel.color != null ? (SectionColors.get(viewModel.color) ?? colors.freePeriodColor) : colors.freePeriodColor,
    backgroundOpacity
  );
  const textColor = viewModel.color != null ? colors.periodWithSectionTextColor : colors.freePeriodTextColor;
  const selectedIndicatorColor =
    viewModel.color != null ? SectionShadowColors.get(viewModel.color) : colors.freePeriodShadowColor;

  const didSelectSection = () => {
    viewModel.didSelectItem();
  };

  return (
    <CardActionArea
      onClick={didSelectSection}
      sx={{ ...sx, backgroundColor, color: textColor, p: 1 }}
      className={className}
    >
      <Stack direction="row" spacing={0.5}>
        <Stack
          sx={{
            flex: 1
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'inherit',
              fontWeight: '500'
            }}
          >
            {viewModel.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'inherit',
              whiteSpace: 'pre-wrap'
            }}
          >
            {viewModel.number.length !== 0 ? viewModel.number : ' '}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'inherit',
              whiteSpace: 'pre-wrap'
            }}
          >
            {viewModel.teacherNames.length !== 0 ? viewModel.teacherNames : ' '}
          </Typography>
        </Stack>

        {isSelected && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                height: 20,
                width: 20,
                borderRadius: '50%',
                backgroundColor: selectedIndicatorColor
              }}
            />
          </Box>
        )}
      </Stack>
    </CardActionArea>
  );
});
