export function withTransparency(color: string, opacity = 0.75): string {
  if (opacity < 0 || opacity > 1) {
    throw new Error('Invalid opacity value.');
  }

  // This works by assumptions on lengths.
  if (color.startsWith('#')) {
    const opacityHex = Math.round(opacity * 255)
      .toString(16)
      .toUpperCase();

    switch (color.length) {
      case 4: // #RGB
        return `${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}${opacityHex}`;

      case 7: // #RRGGBB
        return color + opacityHex;

      default:
        console.error(`Cannot convert color "${color}" to a transparent version.`);
        return color;
    }
  } else if (color.startsWith('rgb(')) {
    return `rgba(${color.substring(4, color.length - 1)}, ${opacity})`;
  } else {
    console.error(`Cannot convert color "${color}" to a transparent version.`);
    return color;
  }
}

export function withTransparencyWhen(isTransparent: boolean | undefined, color: string, opacity = 0.75): string {
  return isTransparent ? withTransparency(color, opacity) : color;
}
