// See https://stackoverflow.com/a/27781331
// Important: do not use the XMLHttpRequest-based solution, as it is not compatible with Safari
export function dataUrlToBlob(dataUrl: string) {
  if (typeof dataUrl !== 'string') {
    throw new Error('Invalid argument: dataUrl must be a string');
  }

  const newDataUrl = dataUrl.split(',');
  const type = newDataUrl[0].split(':')[1].split(';')[0],
    byteString = atob(newDataUrl[1]),
    byteStringLength = byteString.length,
    arrayBuffer = new ArrayBuffer(byteStringLength),
    intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteStringLength; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([intArray], {
    type
  });
}
