import { Stack, SxProps, useTheme } from '@mui/material';
import { Droppable, dragManager } from '@shared/rxp/drag-drop';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';
import { DayAndWeekPeriodContentBoxViewModel } from '../../../viewmodels';
import { ContentNoteBox, DisplayableContentBox, isDisplayableContentDragData } from '../../contents';
import { PeriodCellDragOverIndicator, PeriodCellDragOverType } from '../utils';
import { DayAndWeekConstants } from './DayAndWeekConstants';

export interface DayAndWeekPeriodContentBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: DayAndWeekPeriodContentBoxViewModel;
  canEdit: boolean;
  renderPeriodTag: () => ReactNode;
  hasTransparency?: boolean;
}

export const DayAndWeekPeriodContentBox = observer((props: DayAndWeekPeriodContentBoxProps) => {
  const { sx = [], className, viewModel, renderPeriodTag, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();

  const [dragState, setDragState] = useState<PeriodCellDragOverType | undefined>(undefined);

  const canDragData = viewModel.kind === 'weekly';

  const canDropData = viewModel.kind === 'weekly' && viewModel.canPasteContent;

  const onDragOver = (data: unknown) => {
    if (isDisplayableContentDragData(data)) {
      setDragState(viewModel.compareSection(data.sectionId) ? 'move' : 'copy');
    }
  };

  const onDragLeave = () => {
    setDragState(undefined);
  };

  const onDrop = (data: unknown) => {
    if (isDisplayableContentDragData(data)) {
      dragManager.clearDragData();
      viewModel.copyContent(data.contentId, data.sectionId, data.isDraggingAssignment);
    }

    setDragState(undefined);
  };
  const isDaily = viewModel.kind === 'daily';

  const renderTasksBox = () => {
    if (viewModel.currentDisplayKind === 'notes') {
      return null;
    }

    let iconSize: number;
    let minItemHeight: number;
    let minItemWidth: number;
    let maxItemWidth: number;

    if (viewModel.presentationKind === 'detailed') {
      iconSize = DayAndWeekConstants.detailedIconSize;
      minItemHeight = DayAndWeekConstants.detailedMinItemHeight;
      minItemWidth = DayAndWeekConstants.detailedMinItemWidth;
      maxItemWidth = DayAndWeekConstants.detailedMaxItemWidth;
    } else {
      iconSize = DayAndWeekConstants.iconTitleIconSize;
      minItemHeight = DayAndWeekConstants.iconTitleMinItemHeight;
      minItemWidth = DayAndWeekConstants.iconTitleMinItemWidth;
      maxItemWidth = DayAndWeekConstants.iconTitleMaxItemWidth;
    }

    return (
      <DisplayableContentBox
        sx={{ flexGrow: 1, flexBasis: 0, flexShrink: 0 }}
        viewModel={viewModel.contentBoxViewModel}
        iconSize={iconSize}
        itemSpacing={DayAndWeekConstants.itemSpacing}
        minItemHeight={minItemHeight}
        minItemWidth={minItemWidth}
        maxItemWidth={maxItemWidth}
        representationKind={viewModel.presentationKind}
        accessoryView={isDaily ? undefined : () => renderPeriodTag()}
        backgroundColor={withTransparencyWhen(
          hasTransparency,
          viewModel.isSkipped ? theme.studyo.periods.freePeriodColor : theme.studyo.periods.contentBoxBackgroundColor,
          0.5 * backgroundOpacity
        )}
        canDragTasks={canDragData}
      />
    );
  };

  const renderNotesBox = () => {
    if (viewModel.currentDisplayKind === 'tasks') {
      return null;
    }

    return (
      <ContentNoteBox
        viewModel={viewModel.noteBoxViewModel}
        sx={{
          ml: viewModel.currentDisplayKind !== 'notes' ? 1 : 0,
          flexGrow: 1,
          flexBasis: 0,
          flexShrink: 0
        }}
        hasTransparency={hasTransparency}
      />
    );
  };

  return (
    <Droppable
      sx={sx}
      className={className}
      handleDragOver={onDragOver}
      handleDragLeave={onDragLeave}
      handleDrop={onDrop}
      acceptedType="content"
      canDropData={() => canDropData}
    >
      <Stack
        direction="row"
        sx={{
          flex: 1
        }}
      >
        {renderTasksBox()}
        {renderNotesBox()}
      </Stack>
      {dragState != null && (
        <PeriodCellDragOverIndicator sx={{ position: 'absolute', bottom: 0, left: 0 }} type={dragState} />
      )}
    </Droppable>
  );
});
