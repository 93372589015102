export const PlannerConstants = {
  rowHeightSmall: 175,
  rowHeightMedium: 234,
  rowHeightLarge: 292,
  columnWidth: 200,
  sectionHeaderWidth: 48,
  dayHeaderHeight: 76,
  periodHeaderHeight: 27,

  iconSize: 20,
  itemSpacing: 4,
  minItemHeight: 40
};
