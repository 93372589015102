import { Button, CardActionArea, Popover, Stack, SxProps, Typography } from '@mui/material';
import { SpecialDaySymbolAndColor, SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { SectionColors } from '@shared/models/Colors.ts';
import { Day } from '@shared/models/types';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface DayInfoProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  day?: Day;
  canSelect?: boolean;
  customOnSelect?: () => void;
  roundedCorners?: boolean;
}

export const DayInfo = observer((props: DayInfoProps) => {
  const { localizationService, navigationService, viewModelFactory } = useStudyoServices();
  const { children, canSelect = true, sx = [], className, day, customOnSelect, roundedCorners = false } = props;

  const viewModel = useMemo(() => viewModelFactory.createDayInfo(day), [day]);
  const [dayInfoAnchorRef, setDayInfoAnchorRef] = useState<HTMLDivElement | null>(null);
  const navigate = useNavigateAsync();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const { schoolDay } = viewModel;
  const isSelectable = canSelect && schoolDay != null;

  const symbols: SpecialDaySymbolAndColor[] =
    schoolDay != null
      ? schoolDay.specialDays
          .filter((sp) => sp.symbol != null && sp.symbol != 'none')
          .map((sp) => ({ color: SectionColors.get(sp.color), symbol: sp.symbol }))
      : [];

  const specialDayTitles = schoolDay != null ? schoolDay.specialDays.map((sp) => sp.title).join(', ') : undefined;

  const hideDayInfo = () => {
    setDayInfoAnchorRef(null);
  };

  const goToDayPress = () => {
    viewModel.setCurrentSchoolDayInDaily();
    void navigate(navigationService.getAgendaScreenLocation('daily', viewModel.configId, viewModel.accountId));
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    // Dismissing the popover when navigating between pages. Otherwise, the
    // popover won't be placed correctly anymore.
    if (e.code === 'ArrowLeft' || e.code === 'ArrowRight') {
      hideDayInfo();
    }
  };

  return (
    <>
      <CardActionArea
        component="div"
        disabled={!isSelectable}
        onClick={(e) => {
          if (customOnSelect != null) {
            customOnSelect();
          } else {
            setDayInfoAnchorRef(e.currentTarget);
          }
        }}
        className={className}
        sx={{
          ...sx,
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          cursor: isSelectable ? 'pointer' : 'auto',
          position: 'relative',
          borderRadius: roundedCorners ? 1 : 0
        }}
      >
        {children}
      </CardActionArea>
      {schoolDay != null && (
        <Popover
          open={dayInfoAnchorRef != null}
          anchorEl={dayInfoAnchorRef}
          onClose={hideDayInfo}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <Stack sx={{ width: 350, p: 2 }} spacing={3}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center'
              }}
            >
              <Stack direction="row" spacing={1}>
                <Typography
                  sx={{
                    fontSize: 60,
                    fontWeight: 'lighter'
                  }}
                >
                  {schoolDay.day.day}
                </Typography>

                <Typography variant="body2">{schoolDay.displayCycleDayTitle}</Typography>
              </Stack>

              <Stack
                spacing={1}
                sx={{
                  flex: 1,
                  margin: { left: '1' }
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      userSelect: 'none'
                    }}
                  >
                    {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.dayOfWeek)}
                  </Typography>

                  <Typography sx={{ userSelect: 'none' }}>
                    {schoolDay.day.formattedString(
                      localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
                    )}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  sx={{
                    flex: 1,
                    alignItems: 'center'
                  }}
                >
                  {symbols.length > 0 && (
                    <SpecialDaySymbolGrid
                      sx={{ mr: 1, flexShrink: 0 }}
                      symbols={symbols}
                      squareSize={symbols.length === 1 ? 30 : 14}
                      maxSymbolCount={4}
                      displayKind={symbols.length === 1 ? 'linear' : 'grid'}
                    />
                  )}

                  <Typography variant="body2">{specialDayTitles}</Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button onClick={goToDayPress}>{localizationService.localizedStrings.studyo.utils.dayInfoGoToDay}</Button>
            </Stack>
          </Stack>
        </Popover>
      )}
    </>
  );
});
