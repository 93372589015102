import { Box, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';

export interface BackgroundImageProps {
  sx?: SxProps;
  className?: string;
  image?: string;
  height?: number;
}

export const BackgroundImage = observer(({ sx = [], className, image, height }: BackgroundImageProps) => {
  return (
    image && (
      <Box
        component="img"
        className={className}
        sx={[
          {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: height ?? '100%',
            opacity: 0.2,
            objectFit: 'cover',
            pointerEvents: 'none'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
        src={image}
      />
    )
  );
});
