import * as MPB from '@buf/studyo_studyo.bufbuild_es/studyo/services/metrics_pb';
import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { Metrics } from '@buf/studyo_studyo.connectrpc_es/studyo/services/metrics_connect';
import { AccountSessionsPeriod, ActiveUsersKind, ActiveUsersPeriod } from '@shared/models/types';
import {
  protobufFromAccountSessionsPeriod,
  protobufFromActiveUsersKind,
  protobufFromActiveUsersPeriod
} from '@shared/models/types/EnumConversion';
import { MetricsTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class MetricsGrpcTransport extends BaseGrpcTransport implements MetricsTransport {
  async fetchAccountOQMetrics(
    configId: string,
    accountIds: string[],
    includeHistory: boolean
  ): Promise<PB.AccountOQMetrics[]> {
    console.log(
      `Fetching account OQ metrics with parameters: [configId: ${configId}, accountIds: ${accountIds.join(', ')}]...`
    );

    const request = new MPB.GetAccountOQMetricsRequest();
    request.configId = configId;
    request.accountIds = accountIds;
    request.includeHistory = includeHistory;

    const response = await this.performRequest(Metrics, Metrics.methods.getAccountOQMetrics, request);

    console.log(
      `Successfully fetched account OQ metrics with parameters: [configId: ${configId}, accountIds: ${accountIds.join(
        ', '
      )}].`
    );

    return response.metrics;
  }

  async fetchConfigGlobalStats(configId: string): Promise<PB.ConfigGlobalStats> {
    console.log(`Fetching configuration global stats with parameters: [configId: ${configId}]...`);

    const request = new MPB.GetConfigGlobalStatsRequest();
    request.configId = configId;

    const response = await this.performRequest(Metrics, Metrics.methods.getConfigGlobalStats, request);

    console.log(`Successfully fetched configuration global stats with parameters: [configId: ${configId}].`);

    const pbStats = response.globalStats;

    if (pbStats == null) {
      throw new Error('Unexpected result from backend: A GetConfigGlobalStats request did not return a stats object.');
    }

    return pbStats;
  }

  async fetchActiveUsers(
    configId: string,
    kind: ActiveUsersKind,
    period: ActiveUsersPeriod,
    insertEmptyDataPoints: boolean,
    trimToBeginningOfSchoolYear: boolean,
    trimToEndOfSchoolYear: boolean
  ): Promise<PB.DataPoint[]> {
    console.log(
      `Fetching active users with parameters: [configId: ${configId}, kind: ${kind}, period: ${period}], trimEnd: ${trimToEndOfSchoolYear}...`
    );

    const request = new MPB.GetActiveUsersRequest();
    request.configId = configId;
    request.kind = protobufFromActiveUsersKind(kind);
    request.period = protobufFromActiveUsersPeriod(period);
    request.insertEmptyDataPoints = insertEmptyDataPoints;
    request.trimToBeginningOfSchoolYear = trimToBeginningOfSchoolYear;
    request.trimToEndOfSchoolYear = trimToEndOfSchoolYear;

    const response = await this.performRequest(Metrics, Metrics.methods.getActiveUsers, request);

    console.log(
      `Successfully fetched active users with parameters: [configId: ${configId}, kind: ${kind}, period: ${period}].`
    );

    return response.dataPoints;
  }

  async fetchAccountSessions(
    configId: string,
    accountId: string,
    period: AccountSessionsPeriod,
    insertEmptyDataPoints: boolean,
    trimToBeginningOfSchoolYear: boolean,
    trimToEndOfSchoolYear: boolean
  ): Promise<PB.DataPoint[]> {
    console.log(
      `Fetching account sessions for parameters: [configId: ${configId}, accountId: ${accountId}, period: ${period}]...`
    );

    const request = new MPB.GetAccountSessionsRequest();
    request.configId = configId;
    request.accountId = accountId;
    request.period = protobufFromAccountSessionsPeriod(period);
    request.insertEmptyDataPoints = insertEmptyDataPoints;
    request.trimToBeginningOfSchoolYear = trimToBeginningOfSchoolYear;
    request.trimToEndOfSchoolYear = trimToEndOfSchoolYear;

    const response = await this.performRequest(Metrics, Metrics.methods.getAccountSessions, request);

    console.log(
      `Successfully fetched account sessions for parameters: [configId: ${configId}, accountId: ${accountId}, period: ${period}].`
    );

    return response.dataPoints;
  }

  async fetchStudentDailyTaskMetrics(
    configId: string,
    accountIds: string[],
    insertEmptyDataPoints: boolean,
    trimToBeginningOfSchoolYear: boolean,
    trimToEndOfSchoolYear: boolean
  ): Promise<PB.StudentDailyTaskMetrics[]> {
    console.log(
      `Fetching student daily task metrics with parameters: [configId: ${configId}, accountIds: [${accountIds.join(
        ', '
      )}]]...`
    );

    const request = new MPB.GetStudentDailyTaskMetricsRequest();
    request.configId = configId;
    request.accountIds = accountIds;
    request.insertEmptyDataPoints = insertEmptyDataPoints;
    request.trimToBeginningOfSchoolYear = trimToBeginningOfSchoolYear;
    request.trimToEndOfSchoolYear = trimToEndOfSchoolYear;

    const response = await this.performRequest(Metrics, Metrics.methods.getStudentDailyTaskMetrics, request);

    console.log(
      `Successfully fetched student daily task metrics with parameters: [configId: ${configId}, accountIds: [${accountIds.join(
        ', '
      )}]].`
    );

    return response.metrics;
  }

  async fetchSectionMetrics(configId: string, sectionIds: string[]): Promise<PB.SectionMetrics[]> {
    console.log(
      `Fetching section metrics with parameters: [configId: ${configId}, sectionIds: [${sectionIds.join(', ')}]]...`
    );

    const request = new MPB.GetSectionMetricsRequest();
    request.configId = configId;
    request.sectionIds = sectionIds;

    const response = await this.performRequest(Metrics, Metrics.methods.getSectionMetrics, request);

    console.log(
      `Successfully fetched section metrics with parameters: [configId: ${configId}, sectionIds: [${sectionIds.join(
        ', '
      )}]].`
    );

    return response.metrics;
  }
}
