import { Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { UseCodeView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface UseCodeScreenProps {
  sx?: SxProps;
  className?: string;
}

export const UseCodeScreen = observer((props: UseCodeScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;

  const params = useParams();
  const isInitialConfigNumeric = params.isInitialConfig ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createUseCode(isInitialConfigNumeric === '1'),
    [isInitialConfigNumeric]
  );

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <UseCodeView sx={{ flex: 1, zIndex: 2 }} viewModel={viewModel} />
        </Stack>
      </div>
    </Stack>
  );
});
