import { AccountProfile as PBAccountProfile } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel, SerializableModel } from '../Model';
import { Color } from '../types';
import { colorFromProtobuf } from '../types/EnumConversion';

export interface AccountProfileModel extends SerializableModel<PBAccountProfile> {
  readonly avatarColor: Color;
  readonly privateFirstName: string;
  readonly privateLastName: string;
  readonly publicEmail: string;
  readonly backgroundImageWeb: string;
}

export class AccountProfile extends BaseModel<PBAccountProfile> implements AccountProfileModel {
  constructor(pb: PBAccountProfile) {
    super(pb);
    makeObservable(this);
  }

  //
  // Properties coming directly from Protobuf
  //

  @computed
  get avatarColor(): Color {
    return colorFromProtobuf(this._pb.avatarColor);
  }

  @computed
  get privateFirstName(): string {
    return this._pb.privateFirstName;
  }

  @computed
  get privateLastName(): string {
    return this._pb.privateLastName;
  }

  @computed
  get publicEmail(): string {
    return this._pb.publicEmail;
  }

  @computed
  get backgroundImageWeb(): string {
    return this._pb.backgroundImageWeb;
  }
}
