import { useCallback } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router';
import { NavigateFunctionAsync } from './NavigateFunctionAsync';

export function useNavigateAsync(): NavigateFunctionAsync {
  const navigate = useNavigate();

  return useCallback(
    async (to: To | number, options: NavigateOptions = {}): Promise<void> => {
      let result: void | Promise<void>;

      if (typeof to === 'number') {
        result = navigate(to);
      } else {
        result = navigate(to, options);
      }

      return Promise.resolve(result);
    },
    [navigate]
  );
}
