import { Check } from '@mui/icons-material';
import { ListItemButton, ListItemText, SxProps } from '@mui/material';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { OtherProfilesListProfileViewModel } from '../../../viewmodels';
import { AvatarView } from '../AvatarView';

export interface OtherProfilesListProfileRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: OtherProfilesListProfileViewModel;
}

export const OtherProfilesListProfileRow = observer((props: OtherProfilesListProfileRowProps) => {
  const { viewModel, sx = [], className } = props;
  const navigate = useNavigateAsync();
  const location = useLocation();

  return (
    <ListItemButton sx={sx} className={className} onClick={() => void viewModel.selectProfile(location, navigate)}>
      <AvatarView
        sx={{ mx: 2 }}
        size={40}
        firstName={viewModel.firstName}
        lastName={viewModel.lastName}
        color={viewModel.color}
        isImpersonating={false}
      />

      <ListItemText primary={viewModel.displayName} secondary={viewModel.schoolName} />

      {viewModel.isSelected && <Check fontSize="medium" color="primary" />}
    </ListItemButton>
  );
});
