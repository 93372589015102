import { Button, Divider, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { useNavigateAsync } from '@shared/utils';
import { OnboardingBackgroundImage, OnboardingContentBackgroundView } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../UseStudyoServicesHook';

export interface ErrorScreenProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle: string;
  message?: string;
}

export const ErrorScreen = observer((props: ErrorScreenProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, title, subtitle, message } = props;
  // Yes, same as "not found" page
  const strings = localizationService.localizedStrings.studyo.notFound;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const navigate = useNavigateAsync();

  const goToHomepage = () => {
    void navigate('/');
  };

  return (
    <Stack
      className={className}
      sx={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <div className="login-container">
        <Stack
          sx={{
            flex: 1,
            position: 'relative'
          }}
        >
          <OnboardingContentBackgroundView sx={{ zIndex: 1 }} />

          <Stack
            spacing={2}
            sx={{
              flex: 1,
              zIndex: 2,
              alignItems: { xs: 'stretch', sm: 'center' },
              p: { xs: 2, sm: 3 },
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <Stack
              spacing={1}
              sx={{
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center'
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  px: 4,
                  textAlign: 'center'
                }}
              >
                {subtitle}
              </Typography>
              {message != null && (
                <Typography
                  variant="body1"
                  sx={{
                    px: 4,
                    textAlign: 'center'
                  }}
                >
                  {message}
                </Typography>
              )}
            </Stack>

            <Divider sx={{ width: 300 }} />

            <Button variant="contained" onClick={goToHomepage} sx={{ minWidth: !isExtraSmallScreen ? 350 : undefined }}>
              {strings.goHomeButtonTitle}
            </Button>
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
});
