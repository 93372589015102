import { Box, Stack, SxProps, useTheme } from '@mui/material';
import { IndexPath } from '@shared/components/utils/IndexPath';
import { isSxArray } from '@shared/components/utils/ViewUtils.ts';
import { VirtualizedSectionList } from '@shared/rxp/virtualized-section-list';
import { TimelineListElementMarginType, TimelineListSectionBaseViewModel } from '@studyo/viewmodels';
import { observer } from 'mobx-react-lite';
import { ListSectionHeader, ListSectionHeaderHeight } from '../../../lists';
import { TimelineListHeader } from './TimelineListHeader';
import { TimelineListItem } from './TimelineListItem.tsx';
import { TimelineListStep } from './TimelineListStep';
import { PlannerListInterItemMargin, PlannerListItemHeight, TimelineListStepHeight } from './TimelineListUtils.ts';

export function TimelineListElementMarginForType(type: TimelineListElementMarginType) {
  let margin: number;
  if (type == 'none') {
    margin = 0;
  } else if (type == 'normal') {
    margin = PlannerListInterItemMargin;
  } else {
    margin = PlannerListInterItemMargin * 4;
  }
  return margin;
}

export interface TimelineListComponentProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineListSectionBaseViewModel;
  hasTransparency?: boolean;
}

export const TimelineList = observer(
  ({ sx = [], className, viewModel, hasTransparency = false }: TimelineListComponentProps) => {
    const theme = useTheme();

    const renderHeader = (sectionIndex: number) => {
      const section = viewModel.sections[sectionIndex];

      // This prevents an error on mobile when _sections change (e.g. changing a task state).
      // The SectionList still thinks it has the same number elements as previously, even though
      // it's not the case. As it is a PureComponent, it doesn't rerender itself when the data source
      // change. We didn't find a solution to it, so we decided to handle these by returning an
      // empty view which won't be visible as its height will be 0. It doesn't impact the performance
      // and is the most elegant way to do it.
      if (section == null) {
        return <Box />;
      }

      return <ListSectionHeader key={`timeline-list-section-header-${section.id}`} title={section.title} />;
    };

    const getHeaderHeight = (sectionIndex: number) => {
      const section = viewModel.sections[sectionIndex];

      if (section == null) {
        return 0;
      }

      return ListSectionHeaderHeight(section.title);
    };

    const renderElement = (indexPath: IndexPath) => {
      const section = viewModel.sections[indexPath.section];
      const element = section != null ? section.data[indexPath.index] : null;

      // This prevents an error on mobile when sections change (e.g. changing a task state).
      // The SectionList still thinks it has the same number elements as previously, even though
      // it's not the case. As it is a PureComponent, it doesn't rerender itself when the data source
      // change. We didn't find a solution to it, so we decided to handle these by returning an
      // empty view which won't be visible as its height will be 0. It doesn't impact the performance
      // and is the most elegant way to do it.
      if (element == null) {
        return <Box />;
      }

      if (element.content != null) {
        return (
          <TimelineListItem
            key={`timeline-list-element-${indexPath.section}-${indexPath.index}`}
            viewModel={element.content}
            marginType={element.marginType}
            hasTransparency={hasTransparency}
          />
        );
      } else if (element.step != null) {
        return (
          <TimelineListStep
            key={`timeline-list-element-${indexPath.section}-${indexPath.index}`}
            viewModel={element.step}
            marginType={element.marginType}
            hasTransparency={hasTransparency}
          />
        );
      } else {
        console.warn('Element should either be a content or a step');
        return <Box />;
      }
    };

    const getElementHeight = (indexPath: IndexPath) => {
      const section = viewModel.sections[indexPath.section];
      const element = section != null ? section.data[indexPath.index] : null;

      // This prevents an error on mobile when _sections change (e.g. changing a task state).
      // The SectionList still thinks it has the same number elements as previously, even though
      // it's not the case. As it is a PureComponent, it doesn't rerender itself when the data source
      // change. We didn't find a solution to it, so we decided to handle these by returning an
      // empty view which won't be visible as its height will be 0. It doesn't impact the performance
      // and is the most elegant way to do it.
      if (element == null) {
        return 0;
      }

      const margin = TimelineListElementMarginForType(element.marginType);

      if (element.content != null) {
        return PlannerListItemHeight + margin;
      } else {
        return TimelineListStepHeight + margin;
      }
    };

    const getFooterHeight = () => {
      return 0;
    };

    const renderFooter = () => {
      return <Box />;
    };

    return (
      <Stack
        sx={[
          {
            overflow: 'hidden'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
        className={className}
      >
        <TimelineListHeader viewModel={viewModel} sx={{ m: 0.5 }} />
        <VirtualizedSectionList
          sx={{ flex: 1, backgroundColor: theme.studyo.agenda.timeline.listBackgroundColor }}
          sections={viewModel.sections}
          renderHeaderForSection={renderHeader}
          heightForSectionHeader={getHeaderHeight}
          renderRowAtIndexPath={renderElement}
          heightForRowAtIndexPath={getElementHeight}
          renderFooterForSection={renderFooter}
          heightForSectionFooter={getFooterHeight}
          offsetBottom={20}
        />
      </Stack>
    );
  }
);
