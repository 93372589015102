import { useContext } from 'react';
import { StudyoServicesContext } from './StudyoServicesContext';

export interface backgroundImageInfo {
  readonly backgroundImage: string | undefined;
  readonly hasBackgroundImage: boolean;
  readonly backgroundOpacity: number;
  readonly backgroundOnlyOnHeader: boolean;
}

export const useBackgroundImage = () => {
  const { accountService, settingsStore } = useContext(StudyoServicesContext);

  const hasAccount = accountService.currentDisplayedAccount != null;

  const backgroundImage = accountService.currentBackgroundImage;
  const hasBackgroundImage = (backgroundImage?.length ?? 0) !== 0;
  const backgroundOpacity = hasAccount
    ? settingsStore.getPreferences(accountService.currentDisplayedAccount.id).backgroundOpacity
    : 0.75;
  const backgroundOnlyOnHeader = hasAccount
    ? settingsStore.getPreferences(accountService.currentDisplayedAccount.id).backgroundOnlyOnHeader
    : false;

  return {
    hasBackgroundImage,
    backgroundImage: hasBackgroundImage ? backgroundImage : undefined,
    backgroundOpacity: hasBackgroundImage ? backgroundOpacity : 1.0,
    backgroundOnlyOnHeader: hasBackgroundImage ? backgroundOnlyOnHeader : false
  };
};
