import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActionArea,
  Checkbox,
  FormControlLabel,
  List,
  Slider,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { AllColors, Color } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ListSection } from '../../components';
import { ProfileEditViewModel } from '../../viewmodels';

export interface ProfileEditViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileEditViewModel;
}

export const ProfileEditView = observer((props: ProfileEditViewProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.profileEdit;
  const theme = useTheme();

  // TODO: All this is repeated everywhere.
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileReader = useRef(new FileReader());
  const localFilename = useRef<string | undefined>(undefined);

  useEffect(() => {
    fileReader.current.onload = () => {
      void viewModel.uploadFile(fileReader.current.result as string, localFilename.current);
    };
  }, [fileReader.current]);

  const openFileInput = () => {
    requestAnimationFrame(() => {
      if (fileInputRef.current != null) {
        fileInputRef.current.click();
      }
    });
  };

  const onUploadFile = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];

      if (file != null) {
        readFile(file);
      }
    }
  };

  const readFile = (file: File) => {
    localFilename.current = file.name;
    fileReader.current.readAsDataURL(file);
  };

  const renderColorElement = (color: Color) => {
    const backgroundColor = SectionColors.get(color)!;
    const checkmarkColor = theme.palette.getContrastText(backgroundColor);

    const onPress = () => (viewModel.color = color);
    const isSelected = viewModel.color === color;

    return (
      <CardActionArea
        key={`profile-edit-color-${color}`}
        sx={{ backgroundColor, width: 46, height: 46, borderRadius: 1, position: 'relative' }}
        onClick={onPress}
      >
        {isSelected && (
          <Check sx={{ color: checkmarkColor, width: 22, height: 22, position: 'absolute', right: 4, bottom: 4 }} />
        )}
      </CardActionArea>
    );
  };

  return (
    <List sx={sx} className={className}>
      <ListSection title={strings.generalInfoSectionTitle}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{
            px: 2,
            py: 1
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            variant="filled"
            value={viewModel.firstName}
            onChange={(e) => (viewModel.firstName = e.currentTarget.value)}
            autoFocus
            label={strings.firstnamePlaceholder}
            slotProps={{ input: { inputProps: { maxLength: 50 } } }}
          />

          <TextField
            sx={{ flex: 1 }}
            variant="filled"
            value={viewModel.lastName}
            onChange={(e) => (viewModel.lastName = e.currentTarget.value)}
            label={strings.lastnamePlaceholder}
            slotProps={{ input: { inputProps: { maxLength: 50 } } }}
          />
        </Stack>
      </ListSection>
      <ListSection title={strings.profileColorSectionTitle}>
        <Stack
          direction="row"
          sx={{
            px: 2,
            flexWrap: 'wrap'
          }}
        >
          {AllColors.map((color) => (
            <Box
              key={color}
              sx={{
                m: 1
              }}
            >
              {renderColorElement(color)}
            </Box>
          ))}
        </Stack>
      </ListSection>

      <ListSection title={strings.backgroundImageTitle}>
        <Stack px={2} spacing={2}>
          <input
            type="file"
            id="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => onUploadFile(e.target.files)}
          />
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={openFileInput}>
              {strings.uploadImage}
            </Button>
            {viewModel.backgroundImage.length > 0 && (
              <Button variant="outlined" onClick={() => viewModel.clearBackgroundImage()}>
                {strings.clearImage}
              </Button>
            )}
          </Stack>
          {viewModel.backgroundImage.length > 0 && (
            <>
              <img
                src={viewModel.backgroundImage}
                width={400}
                height={300}
                alt="Current background image"
                style={{ objectFit: 'cover' }}
              />
              <Stack direction="row" sx={{ width: 400 }} alignItems="center" spacing={2}>
                <Typography variant="caption">{strings.backgroundImageOpacityLabel}</Typography>
                <Slider
                  min={0.5}
                  max={1}
                  step={0.01}
                  value={viewModel.backgroundOpacity}
                  onChange={(e, v) => (viewModel.backgroundOpacity = v as number)}
                />
                <Typography variant="caption">{viewModel.backgroundOpacity.toFixed(2)}</Typography>
              </Stack>
              <FormControlLabel
                sx={{ maxWidth: 400 }}
                control={
                  <Checkbox
                    checked={viewModel.backgroundOnlyOnHeader}
                    onChange={(_, checked) => (viewModel.backgroundOnlyOnHeader = checked)}
                  />
                }
                label={strings.backgroundOnlyOnHeader}
              />
            </>
          )}
        </Stack>
      </ListSection>
    </List>
  );
});
