import { Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { WeeklyViewModel } from '../../../viewmodels';
import { DayAndWeekSchoolDayPresenter } from '../presenter/DayAndWeekSchoolDayPresenter';

export interface WeeklySchoolDaysProps {
  sx?: SxProps;
  className?: string;
  viewModel: WeeklyViewModel;
  hasTransparency?: boolean;
}

export const WeeklySchoolDays = observer(
  ({ sx = [], className, viewModel, hasTransparency }: WeeklySchoolDaysProps) => {
    return (
      <Stack direction="row" sx={sx} className={className}>
        {viewModel.currentDays.map((sd, i) => (
          <DayAndWeekSchoolDayPresenter
            key={`school-day-${i}`}
            pointsPerHour={viewModel.pointsPerHour}
            isCompact={true}
            isSmallPeriodTag={true}
            viewModel={sd}
            displayType={viewModel.currentDisplayKind}
            hasTransparency={hasTransparency}
            sx={{
              flex: 1
            }}
          />
        ))}
      </Stack>
    );
  }
);
