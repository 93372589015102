import { css } from '@emotion/css';
import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ObserverAutoSizer } from '@shared/components/layout';
import { DateUtils } from '@shared/components/utils';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import {
  AgendaTimelineCollectionViewViewModel,
  CollectionViewItemPadding,
  DayHeaderHeight,
  LargeContentLineHeight,
  LargeSingleDayWidth,
  SectionHeaderWidth,
  SingleDayWidth,
  StandardContentLineHeight
} from '@studyo/viewmodels';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { TimelineCollectionViewItem } from './TimelineCollectionViewItem';
import { TimelineCollectionViewSchoolDayHeader } from './TimelineCollectionViewSchoolDayHeader';
import { TimelineCollectionViewSectionHeader } from './TimelineCollectionViewSectionHeader';

export interface AgendaTimelineCollectionViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AgendaTimelineCollectionViewViewModel;
  hasTransparency?: boolean;
}

export const AgendaTimelineCollectionView = observer(
  ({ sx = [], className, viewModel, hasTransparency = false }: AgendaTimelineCollectionViewProps) => {
    const { localizationService } = useStudyoServices();
    const { backgroundOpacity } = useBackgroundImage();
    const theme = useTheme();

    const renderSchoolDayHeaderViews = (startIndex: number, dayWidth: number, numberOfDays: number) => {
      return [
        <Box
          key={`timeline-schoolday-header-0`}
          sx={{ width: SectionHeaderWidth, height: DayHeaderHeight, flexShrink: 0 }}
        />,

        ...times(numberOfDays).map((i) => (
          <TimelineCollectionViewSchoolDayHeader
            key={`timeline-schoolday-header-${i + 1}`}
            viewModel={viewModel.schoolDayViewModelAtIndex(i + startIndex)}
            itemWidth={dayWidth}
            sx={{ flexShrink: 0 }}
          />
        ))
      ];
    };

    const renderSectionHeaderViews = () => {
      return viewModel.sections.map((element, i) => (
        <TimelineCollectionViewSectionHeader
          sx={{ zIndex: 5, flexShrink: 0 }}
          key={`timeline-section-header-${i}`}
          viewModel={element}
          hasTransparency={hasTransparency}
        />
      ));
    };

    const renderDayViews = (startIndex: number, dayWidth: number, numberOfDays: number, height: number) => {
      const dayViewClassName = css({
        width: dayWidth,
        height: height,
        flexShrink: 0,
        position: 'relative'
      });

      return times(numberOfDays).map((i) => {
        let backgroundColor = theme.studyo.agenda.timeline.collectionViewDayViewBackgroundColor;
        let opacity = 1.0;

        const schoolDay = viewModel.schoolDayAtIndex(i + startIndex);

        if (schoolDay != null) {
          if (DateUtils.isToday(schoolDay.day)) {
            backgroundColor = theme.studyo.todaySymbolBackgroundColor;
            opacity = hasTransparency ? 0.8 * backgroundOpacity : 0.3;
          } else if (DateUtils.isWeekend(schoolDay.day)) {
            opacity = hasTransparency ? backgroundOpacity : 0.6;
          }
        }

        const backgroundColorClassName = css({
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: withTransparencyWhen(hasTransparency, backgroundColor),
          opacity: opacity
        });

        return (
          <Box key={`timeline-day-view-${i}`} className={dayViewClassName}>
            <Box className={backgroundColorClassName} />
            {/* Not using a border on the root box, as Windows renders them smaller than 1px.
              Not using a border on the background box, as its opacity changes.
              Not using an actual 1px separator, as this is too strong. */}
            <Box
              sx={{
                position: 'relative',
                width: '0.5px',
                height: height,
                right: 0,
                backgroundColor: theme.studyo.agenda.timeline.collectionViewSeparatorColor
              }}
            />
          </Box>
        );
      });
    };

    const renderContentItems = (dayWidth: number, lineHeight: number) => {
      return viewModel.contentItems.map((element, i) => {
        const leftIndex = element.startColumnIndex - viewModel.startDayIndex;

        return (
          <TimelineCollectionViewItem
            key={`timeline-collectionview-item-${i}`}
            sx={{
              position: 'absolute',
              top: element.rowIndex * lineHeight + CollectionViewItemPadding + lineHeight / 2,
              left: leftIndex * dayWidth + SectionHeaderWidth,
              zIndex: 3
            }}
            viewModel={element.vm}
            itemWidth={dayWidth}
            itemHeight={lineHeight}
          />
        );
      });
    };

    return (
      <Box
        sx={{
          ...sx,
          backgroundColor: theme.studyo.agenda.timeline.listBackgroundColor,
          display: 'flex',
          overflow: 'hidden'
        }}
        className={className}
      >
        <ObserverAutoSizer>
          {({ width, height }) => {
            const dayColumnsWidth = width - SectionHeaderWidth;
            const dayWidth = viewModel.cellSize === 'large' ? LargeSingleDayWidth : SingleDayWidth;
            const numberOfDays = Math.floor(dayColumnsWidth / dayWidth) + 1;
            viewModel.setCalculatedNumberOfDays(numberOfDays);

            return (
              <Stack sx={{ width, height, overflow: 'hidden' }}>
                {viewModel.maxRowIndex !== -1 && (
                  <Stack sx={{ flex: 1, overflow: 'hidden' }}>
                    <Stack direction="row" sx={{ overflow: 'hidden' }}>
                      {renderSchoolDayHeaderViews(viewModel.startDayIndex, dayWidth, numberOfDays)}
                    </Stack>

                    <Box sx={{ flex: 1 }}>
                      <ObserverAutoSizer>
                        {(bounds) => {
                          const lineHeight =
                            viewModel.cellSize === 'large' ? LargeContentLineHeight : StandardContentLineHeight;
                          const contentHeight = (viewModel.maxRowIndex + 2) * lineHeight;
                          const maxHeight = Math.max(contentHeight, bounds.height);
                          const marginTop = lineHeight / 2;

                          return (
                            <Box
                              sx={{
                                overflowX: 'hidden',
                                overflowY: 'auto',
                                display: 'flex',
                                position: 'relative'
                              }}
                              ref={(ref) => viewModel.setScrollViewReference(ref as HTMLDivElement | null)}
                            >
                              <Stack width={SectionHeaderWidth} flexShrink={0}>
                                <Box sx={{ backgroundColor: 'transparent', height: marginTop, flexShrink: 0 }} />
                                {renderSectionHeaderViews()}
                                <Box sx={{ backgroundColor: 'transparent', height: marginTop, flexShrink: 0 }} />
                              </Stack>

                              {renderDayViews(viewModel.startDayIndex, dayWidth, numberOfDays, maxHeight)}
                              {renderContentItems(dayWidth, lineHeight)}
                            </Box>
                          );
                        }}
                      </ObserverAutoSizer>
                    </Box>
                  </Stack>
                )}

                {viewModel.maxRowIndex === -1 && (
                  <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography>
                      {localizationService.localizedStrings.studyo.agenda.timeline.noRemainingTasks}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            );
          }}
        </ObserverAutoSizer>
      </Box>
    );
  }
);
