import qs from 'qs';
import { Location } from 'react-router';

export const UrlUtils = {
  getLoginCompletion(location: Location): boolean {
    const result = this.getQueryParamValue(location.search, 'completion');

    // A `null` value means the parameter is present without a value.
    return result != null || result === null;
  },
  getQueryParamValue(search: string, paramName: string): string | undefined {
    const queryParams =
      qs.parse(search ?? '', {
        ignoreQueryPrefix: true,
        strictNullHandling: true
      }) || {};

    return queryParams[paramName] as string | undefined;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQueryParamValue(search: string, paramName: string, value: any): string {
    // Extract the params from the search field
    const queryParams =
      qs.parse(search ?? '', {
        ignoreQueryPrefix: true,
        strictNullHandling: true
      }) || {};

    // Set the param value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    queryParams[paramName] = value;

    // Convert back to a search string and return it.
    return qs.stringify(queryParams, { addQueryPrefix: true, strictNullHandling: true });
  },
  removeQueryParamValue(search: string, paramName: string): string {
    // Setting a query param to undefined will actually remove the param from the URL
    return this.setQueryParamValue(search, paramName, undefined);
  }
};
