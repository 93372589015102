import { Circle } from '@mui/icons-material';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { withTransparencyWhen } from '@studyo/theme/ColorUtils.ts';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook.ts';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../../UseStudyoServicesHook';
import { TimelineListElementMarginType, TimelineListStepViewModel, TimelineUtils } from '../../../../viewmodels';
import { TimelineListElementMarginForType } from './TimelineList';
import { StateIndicatorViewWidth, TimelineListStepHeight } from './TimelineListUtils.ts';

export interface TimelineListStepProps {
  sx?: SxProps;
  className?: string;
  viewModel: TimelineListStepViewModel;
  marginType: TimelineListElementMarginType;
  hasTransparency?: boolean;
}

export const TimelineListStep = observer((props: TimelineListStepProps) => {
  const { localizationService } = useStudyoServices();
  const { sx = [], className, marginType, viewModel, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();

  const isLate = viewModel.state == 'late';
  const stateColor = TimelineUtils.getStateColor(viewModel.state, theme);

  return (
    <Box
      className={className}
      sx={{
        overflow: 'hidden',
        ...sx,
        display: 'flex',
        height: TimelineListStepHeight,
        pl: 1,
        alignItems: 'center',
        py: '2px',
        ml: '81px',
        marginRight: `${StateIndicatorViewWidth}px`,
        marginBottom: `${TimelineListElementMarginForType(marginType)}px`,
        backgroundColor: withTransparencyWhen(
          hasTransparency,
          theme.studyo.agenda.timeline.contentInfoContainerBackgroundColor,
          backgroundOpacity
        ),
        borderBottomLeftRadius: marginType !== 'none' ? 4 : 0,
        borderBottomRightRadius: marginType !== 'none' ? 4 : 0
      }}
    >
      <Circle sx={{ height: 12, width: 12, color: stateColor, flexShrink: 0 }} />
      <Box
        sx={{
          backgroundColor: stateColor,
          flexShrink: 0,
          width: '2px',
          height: TimelineListStepHeight - 10,
          mx: 0.5
        }}
      />
      <Typography
        variant="caption"
        noWrap
        sx={{
          flex: 1,
          userSelect: 'none'
        }}
      >
        {viewModel.title ?? localizationService.localizedStrings.studyo.agenda.timeline.noTitle}
      </Typography>
      {viewModel.date != null && (
        <Typography
          variant="caption"
          noWrap
          sx={{
            color: (theme) => theme.palette.text.secondary,
            mx: 0.5,
            fontStyle: 'italic',
            userSelect: 'none'
          }}
        >
          {viewModel.date.formattedString(localizationService.localizedStrings.models.dateFormats.shortUnabridged)}
        </Typography>
      )}
      <Box
        sx={{
          width: 22,
          flexShrink: 0,
          height: TimelineListStepHeight,
          alignItems: 'center',
          justifyContent: 'center',
          mr: 0.5,
          display: 'flex'
        }}
      >
        {isLate && (
          <Typography
            variant="body1"
            sx={{
              fontWeight: '600',
              color: (theme) => theme.palette.error.main
            }}
          >
            !
          </Typography>
        )}
      </Box>
    </Box>
  );
});
