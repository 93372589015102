import { Box, Divider, List, Stack, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { NetworkService } from '@shared/services';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import {
  ActiveProfileBox,
  OtherProfilesRow,
  ProfileMenuCreateConfigRow,
  ProfileMenuDemoRow,
  ProfileMenuNewYearMessageRow,
  ProfileMenuOptionRow,
  ProfileMenuProfileRow,
  ProfileMenuSynchronizeRow,
  StudyoLogo
} from '../../components';
import { ProfileMenuViewModel } from '../../viewmodels';

export interface ProfileMenuViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProfileMenuViewModel;
  networkService?: NetworkService;
}

export const ProfileMenuView = observer((props: ProfileMenuViewProps) => {
  const { viewModel, sx = [], className } = props;
  const navigate = useNavigateAsync();

  return (
    <Box
      className={className}
      sx={[
        {
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <List sx={{ width: '100%', height: '100%', overflow: 'auto', pb: 2 }}>
        <ActiveProfileBox viewModel={viewModel.activeProfile} />
        <Divider />

        {viewModel.displayNewSchoolYearMessage && viewModel.currentRole !== 'individual' && (
          <>
            <ProfileMenuNewYearMessageRow role={viewModel.currentRole} />
            <ProfileMenuCreateConfigRow action={() => void viewModel.createNewConfig(navigate)} />
          </>
        )}

        {viewModel.currentYearProfiles.map((profile, i) => (
          <ProfileMenuProfileRow key={`profile-menu-profile-${i}`} viewModel={profile} />
        ))}

        <OtherProfilesRow onPress={() => viewModel.presentOtherProfiles()} />
        <Divider />

        {viewModel.options.map((option, index) => (
          <Fragment key={`profile-menu-option-${index}`}>
            <ProfileMenuOptionRow viewModel={option} />
            <Divider />
          </Fragment>
        ))}

        <ProfileMenuSynchronizeRow viewModel={viewModel.synchronizeOption} />

        {viewModel.demoOptions.isDemoSchool && <ProfileMenuDemoRow sx={{ my: 2 }} viewModel={viewModel.demoOptions} />}

        <Stack
          direction="column"
          spacing={0.5}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            py: 2
          }}
        >
          <StudyoLogo style={{ height: 60 }} />

          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textAlign: 'center'
            }}
          >
            {viewModel.version}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textAlign: 'center'
            }}
          >
            {viewModel.copyright}
          </Typography>
        </Stack>
      </List>
    </Box>
  );
});
