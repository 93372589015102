import { Browser as CapacitorBrowser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import * as SentryCapacitor from '@sentry/capacitor';
import { replayIntegration } from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { BrowserOptions, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import { SharedServicesContext } from '@shared/SharedServicesContext.tsx';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { router } from './Router.tsx';
import { StudyoServiceContainer } from './StudyoServiceContainer.ts';
import { StudyoServicesContext } from './StudyoServicesContext.tsx';

// The `public/environment.js` scripts, loaded via the ≤head≥ tag in `index.html`, injects those environment
// variables into the global Window object.
declare global {
  interface StudyoEnv {
    environmentName: string;
    version: string;
    buildNumber: string;
    insightsBaseUrl: string;
    grafanaBaseUrl: string;
    apiV3Url: string;
    killSwitchUrl: string;
    authServiceUrl: string;
    authClientId: string;
    intercomAppId: string;
    googleClientId: string;
    googleDrivePickerKey: string;
    applicationInsightsConnectionString: string;
    firebaseConfig: {
      apiKey: string;
      authDomain: string;
      databaseURL?: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
      measurementId: string;
    };
  }

  interface Window {
    STUDYO_ENV: StudyoEnv;
  }
}

if (Capacitor.getPlatform() === 'web') {
  const sentryOptions: BrowserOptions = {
    dsn: 'https://631987797fd8a70a9fead29747f5537e@o187895.ingest.sentry.io/4505761414316032',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      replayIntegration()
    ],

    // Session Replay
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Tracing
    tracesSampleRate: 0.1
  };

  if (window.STUDYO_ENV != null) {
    sentryOptions.release = `studyo-web@${window.STUDYO_ENV.version}+${window.STUDYO_ENV.buildNumber}`;
    sentryOptions.environment = window.STUDYO_ENV.environmentName;
  }

  SentryReact.init(sentryOptions);
} else if (Capacitor.getPlatform() === 'android') {
  const sentryOptions: SentryCapacitor.CapacitorOptions = {
    dsn: 'https://8fb97e64b64a8b00a9a9f6d1cd2e107a@o187895.ingest.sentry.io/4505986847866880'
  };

  if (window.STUDYO_ENV != null) {
    sentryOptions.release = `studyo-android@${window.STUDYO_ENV.version}+${window.STUDYO_ENV.buildNumber}`;
    sentryOptions.environment = window.STUDYO_ENV.environmentName;
  }

  SentryCapacitor.init(sentryOptions);
}

if (Capacitor.isNativePlatform()) {
  window.open = (url) => {
    if (url instanceof URL) {
      void CapacitorBrowser.open({ url: url.toString() });
    } else if (url != null) {
      void CapacitorBrowser.open({ url });
    }
    return null;
  };
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <StudyoServicesContext.Provider value={StudyoServiceContainer.services}>
      <SharedServicesContext.Provider value={{ ...StudyoServiceContainer.services }}>
        <RouterProvider router={router} />
      </SharedServicesContext.Provider>
    </StudyoServicesContext.Provider>
  </StrictMode>
);
