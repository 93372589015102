import { CircularProgress, Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { OnboardingBackgroundImage } from '../components';

export interface LogoutScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LogoutScreen = observer((props: LogoutScreenProps) => {
  const { viewModelFactory } = useStudyoServices();
  const { sx = [], className } = props;

  const viewModel = useMemo(() => viewModelFactory.createLogout(), []);
  const navigate = useNavigateAsync();
  useEffect(() => {
    void viewModel.onInit(navigate);
  }, [viewModel]);

  return (
    <Stack
      className={className}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <OnboardingBackgroundImage />
      <CircularProgress sx={{ zIndex: 2 }} />
    </Stack>
  );
});
