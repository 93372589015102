import { CircularProgress, Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { RouteParamNames, RouteTemplates } from '../../../Routes';
import { useStudyoServices } from '../../../UseStudyoServicesHook';

export interface LandingScreenProps {
  sx?: SxProps;
  className?: string;
}

export const LandingScreen = observer((props: LandingScreenProps) => {
  const { accountService, reactRouterRouteService } = useStudyoServices();
  const { sx = [], className } = props;
  const [isInitializing, setIsInitializing] = useState(true);

  const initializeDefaultAccount = async () => {
    try {
      setIsInitializing(true);
      await accountService.setCurrentDisplayedAccountToDefault();
    } finally {
      setIsInitializing(false);
    }
  };

  useEffect(() => {
    void initializeDefaultAccount();
  }, []);

  if (isInitializing) {
    return (
      <Stack
        className={className}
        sx={[
          {
            alignItems: 'center',
            justifyContent: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <CircularProgress />
      </Stack>
    );
  }

  // The user does not have any account, navigate to the onboarding flow.
  if (accountService.accounts.length === 0) {
    return (
      <Navigate
        to={reactRouterRouteService.resolveLocation(RouteTemplates.onboarding.configCreation, [
          { name: RouteParamNames.isInitialConfig, value: '1' }
        ])}
        replace
      />
    );
  }

  const currentDisplayedAccount = accountService.currentDisplayedAccount!;

  return (
    <Navigate
      to={reactRouterRouteService.resolveLocation(RouteTemplates.config.account.agenda.path, [
        { name: RouteParamNames.configId, value: currentDisplayedAccount.configId },
        { name: RouteParamNames.accountId, value: currentDisplayedAccount.id }
      ])}
      replace
    />
  );
});
