import { Box, Divider, Stack, SxProps, useTheme } from '@mui/material';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { WeeklyViewModel } from '../../../viewmodels';
import { DayAndWeekConstants } from '../shared';
import { WeeklyDayHeader } from '../weekly/WeeklyDayHeader';

export interface SchoolDayHeaderRowProps {
  sx?: SxProps;
  className?: string;
  viewModel: WeeklyViewModel;
}

export const SchoolDayHeaderRow = observer((props: SchoolDayHeaderRowProps) => {
  const { sx = [], className, viewModel } = props;
  const theme = useTheme();
  const { hasBackgroundImage, backgroundOpacity } = useBackgroundImage();
  const backgroundColor = withTransparencyWhen(
    hasBackgroundImage,
    theme.studyo.agenda.header.dateRowBackgroundColor,
    backgroundOpacity
  );
  const leftMargin = hasBackgroundImage
    ? DayAndWeekConstants.timesColumnWidth + DayAndWeekConstants.itemSpacing * 2
    : 0;

  return (
    <Stack sx={sx} className={className}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          overflow: 'hidden',
          backgroundColor,
          ml: `${leftMargin}px`
        }}
      >
        {!hasBackgroundImage && (
          <Box
            sx={{
              backgroundColor,
              width: DayAndWeekConstants.timesColumnWidth + DayAndWeekConstants.itemSpacing * 2,
              flexShrink: 0
            }}
          />
        )}
        {viewModel.currentDays.map((sd, i) => (
          <WeeklyDayHeader
            key={'header-' + i}
            viewModel={sd.weeklyDayHeaderViewModel}
            sx={{
              flexBasis: 0,
              flexGrow: 1,
              flexShrink: 0,
              ml: i > 0 ? 1 : 0,
              mr: 1
            }}
            contentBoxViewModel={sd.dayContentsViewModel}
          />
        ))}
      </Stack>
      <Divider />
    </Stack>
  );
});
