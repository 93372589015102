import { NavigateFunctionAsync } from '@shared/utils';
import { ReactRouterRouteService } from '@shared/web/services';
import { computed, makeObservable } from 'mobx';
import { RouteParamNames, RouteTemplates } from '../../../Routes.ts';
import { AccountService, NavigationService } from '../../../services';
import { AppOtherProfilesListProfileViewModel } from './OtherProfilesListProfileViewModel';
import {
  AppOtherProfilesListSectionViewModel,
  OtherProfilesListSectionViewModel
} from './OtherProfilesListSectionViewModel';

export interface OtherProfilesListViewModel {
  readonly sections: OtherProfilesListSectionViewModel[];
  useCode(navigate: NavigateFunctionAsync): void;
  dismiss(): void;
}

export class AppOtherProfilesListViewModel implements OtherProfilesListViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _reactRouterRouteService: ReactRouterRouteService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get sections(): OtherProfilesListSectionViewModel[] {
    const sections: AppOtherProfilesListSectionViewModel[] = [];

    this._accountService.accounts.forEach((account) => {
      if (account.role === 'parent') {
        account.childrenAccountSummaries.forEach((childAccount) => {
          const profile = new AppOtherProfilesListProfileViewModel(
            account,
            childAccount,
            this._accountService,
            this._navigationService,
            this._onSuccess
          );
          const existingSection = sections.find((s) => s.shouldContainAccountSummaryProfile(account));

          if (existingSection != null) {
            existingSection.addProfile(profile);
          } else {
            const newSection = new AppOtherProfilesListSectionViewModel(
              account.configurationSummary!.startDay.year,
              account.configurationSummary!.endDay.year,
              [profile]
            );
            sections.push(newSection);
          }
        });
      } else {
        const profile = new AppOtherProfilesListProfileViewModel(
          account,
          undefined,
          this._accountService,
          this._navigationService,
          this._onSuccess
        );
        const existingSection = sections.find((s) => s.shouldContainAccountSummaryProfile(account));

        if (existingSection != null) {
          existingSection.addProfile(profile);
        } else {
          const newSection = new AppOtherProfilesListSectionViewModel(
            account.configurationSummary!.startDay.year,
            account.configurationSummary!.endDay.year,
            [profile]
          );
          sections.push(newSection);
        }
      }
    });

    sections.sort((s1, s2) => s1.compare(s2));
    sections.reverse();
    return sections;
  }

  useCode(navigate: NavigateFunctionAsync) {
    void this._navigationService.closeAllModals();
    const location = this._reactRouterRouteService.resolveLocation(RouteTemplates.onboarding.useCode, [
      { name: RouteParamNames.isInitialConfig, value: '0' }
    ]);
    void navigate(location);
  }

  dismiss() {
    this._onCancel();
  }
}
