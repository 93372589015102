import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { PeriodsListItemViewModel } from '../../../viewmodels';
import { ContentNoteBox, DisplayableContentBox } from '../../contents';

export interface PeriodsListItemProps {
  sx?: SxProps;
  className?: string;
  height: number;
  viewModel: PeriodsListItemViewModel;
  hasTransparency?: boolean;
}

export const PeriodsListItem = observer((props: PeriodsListItemProps) => {
  const { height, sx = [], className, viewModel, hasTransparency = false } = props;
  const { localizationService } = useStudyoServices();
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();
  const colors = theme.studyo.periods;
  const defaultBackgroundColor = viewModel.isSkipped ? colors.skippedPeriodColor : colors.freePeriodColor;
  const backgroundColor = withTransparencyWhen(
    hasTransparency,
    viewModel.color != null ? (SectionColors.get(viewModel.color) ?? defaultBackgroundColor) : defaultBackgroundColor,
    backgroundOpacity
  );

  const textColor = viewModel.color != null ? colors.periodWithSectionTextColor : colors.freePeriodTextColor;

  const dayInfoText = viewModel.day.formattedString(
    localizationService.localizedStrings.models.dateFormats.shortUnabridged
  );
  const periodInfoText = `${dayInfoText}, ${viewModel.startTime.asString} - ${viewModel.endTime.asString}`;

  return (
    <Stack
      className={className}
      sx={{
        overflow: 'hidden',
        ...sx,
        height
      }}
    >
      <Stack
        direction="row"
        sx={{
          py: 0.5,
          px: 1,
          alignItems: 'center',
          backgroundColor,
          color: textColor
        }}
      >
        <Stack
          direction="row"
          sx={{
            flexBasis: 0,
            flexGrow: 1
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              flex: 1,
              color: 'inherit'
            }}
          >
            {periodInfoText}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'inherit'
            }}
          >
            {viewModel.occurrenceTitle}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{
            flexBasis: 0,
            flexGrow: 1,
            justifyContent: 'flex-end'
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'inherit'
            }}
          >
            {localizationService.localizedStrings.studyo.agenda.periods.occurrencesLeft(viewModel.occurrencesLeft)}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          flex: 1,
          overflow: 'hidden'
        }}
      >
        <DisplayableContentBox
          sx={{ p: 0.5, flexBasis: 0, flexGrow: 1, flexShrink: 0 }}
          viewModel={viewModel.tasksDisplayableContentViewModel}
          iconSize={26}
          itemSpacing={4}
          minItemHeight={50}
          minItemWidth={60}
          maxItemWidth={100}
          representationKind="icon-title"
          backgroundColor={withTransparencyWhen(
            hasTransparency,
            viewModel.isSkipped ? theme.studyo.periods.freePeriodColor : theme.studyo.periods.contentBoxBackgroundColor,
            0.5
          )}
        />

        <ContentNoteBox
          sx={{
            flexBasis: 0,
            flexGrow: 3,
            flexShrink: 1
          }}
          viewModel={viewModel.notesDisplayableContentViewModel}
          hasTransparency={hasTransparency}
        />
      </Stack>
    </Stack>
  );
});
