import { App as CapacitorApp } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { Box, Stack } from '@mui/material';
import { withProfiler } from '@sentry/react';
import { ThemedScreen } from '@shared/ThemedScreen.tsx';
import { useNavigateAsync } from '@shared/utils';
import {
  BrowserDetector,
  BrowserHistoryListener,
  ErrorBoundary,
  NoInternetIndicator,
  UpdateIndicator
} from '@studyo/components';
import { InitializingScreen } from '@studyo/screens';
import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Fragment, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import './App.css';
import { useStudyoServices } from './UseStudyoServicesHook';
import { UrlUtils } from './utils/UrlUtils.ts';

configure({
  enforceActions: 'observed'
});

const AppComponent = observer(() => {
  const { modalService, uiSettingsStore, startUpService } = useStudyoServices();
  const { isReady } = startUpService;

  const location = useLocation();
  const navigate = useNavigateAsync();

  useLayoutEffect(() => {
    // Set this before rendering any login page, to ensure the LoginViewModel knows early.
    uiSettingsStore.isCompletingLogin = UrlUtils.getLoginCompletion(location);
    void startUpService.start();
  }, []);

  void SplashScreen.hide();

  void CapacitorApp.addListener('backButton', (e) => {
    if (e.canGoBack) {
      if (modalService.isDisplayingModal) {
        modalService.popModal();
      } else {
        void navigate(-1);
      }
    } else {
      void CapacitorApp.exitApp();
    }
  });

  return (
    <ThemedScreen>
      <ErrorBoundary>
        {!isReady ? (
          <InitializingScreen sx={{ flex: 1 }} />
        ) : (
          <>
            <BrowserHistoryListener />
            <BrowserDetector sx={{ flex: 1 }}>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  overflow: 'hidden'
                }}
              >
                <Stack
                  sx={{
                    flex: 1,
                    overflow: 'hidden'
                  }}
                >
                  <UpdateIndicator />
                  <NoInternetIndicator />

                  <Stack
                    sx={{
                      flex: 1,
                      overflow: 'hidden'
                    }}
                  >
                    <Outlet />
                  </Stack>
                </Stack>

                {modalService.modals.map((m, index) => (
                  <Fragment key={index}>{m.element}</Fragment>
                ))}
              </Box>
            </BrowserDetector>
          </>
        )}
      </ErrorBoundary>
    </ThemedScreen>
  );
});

export const App = withProfiler(AppComponent);
