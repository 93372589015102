import { NavigateFunctionAsync } from '@shared/utils';

export interface ProfileMenuOptionViewModel {
  readonly icon: string;
  readonly title: string;

  action(navigate: NavigateFunctionAsync): void;
}

export class AppProfileMenuOptionViewModel implements ProfileMenuOptionViewModel {
  constructor(
    public readonly icon: string,
    public readonly title: string,
    public action: (navigate: NavigateFunctionAsync) => void
  ) {}
}
