import { alpha, Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SpecialDaySymbolAndColor, SpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { MonthDayViewModel } from '../../../viewmodels';
import { DisplayableContentBox } from '../../contents';
import { DayInfo } from '../utils';

export interface MonthDayProps {
  sx?: SxProps;
  className?: string;
  viewModel: MonthDayViewModel | undefined;
  hasTransparency?: boolean;
}

const taskIconSize = 24;

export const MonthDay = observer((props: MonthDayProps) => {
  const { sx = [], className, viewModel, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();

  if (viewModel == null) {
    return <Box sx={sx} className={className} />;
  }

  const allSymbols: SpecialDaySymbolAndColor[] = viewModel.specialDays
    .filter((sp) => sp.symbol !== 'none')
    .map((sp) => ({
      symbol: sp.symbol,
      color: SectionColors.get(sp.color)
    }));

  const { day } = viewModel.schoolDay;
  const isToday = DateUtils.isToday(day);

  return (
    <Stack
      sx={{
        ...sx,
        backgroundColor: withTransparencyWhen(
          hasTransparency,
          theme.studyo.agenda.month.dayBackgroundColor,
          backgroundOpacity
        ),
        position: 'relative'
      }}
      className={className}
    >
      {isToday && (
        <Box
          sx={{
            backgroundColor: alpha(theme.studyo.todaySymbolBackgroundColor, 0.15),
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
          }}
        />
      )}
      <DayInfo sx={{ p: 1 }} day={viewModel.schoolDay.day} key={viewModel.schoolDay.day.asString}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden'
          }}
        >
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body1" sx={{ userSelect: 'none' }}>
              {day.day}
            </Typography>

            {viewModel.schoolDay.displayCycleDayTitle.length > 0 && (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  userSelect: 'none'
                }}
              >
                {viewModel.schoolDay.displayCycleDayTitle}
              </Typography>
            )}
          </Stack>

          {allSymbols.length > 0 && (
            <SpecialDaySymbolGrid
              squareSize={allSymbols.length > 1 ? 10 : 22}
              maxSymbolCount={4}
              displayKind={allSymbols.length > 1 ? 'grid' : 'linear'}
              symbols={allSymbols}
            />
          )}

          <Stack
            direction="row"
            sx={{
              flex: 1,
              overflow: 'hidden'
            }}
          >
            {viewModel.specialDays.map((specialDay, i) => {
              const isLast = i === viewModel.specialDays.length - 1;
              const suffix = !isLast ? ', ' : '';
              return (
                <Typography
                  key={i}
                  noWrap
                  variant="caption"
                  sx={{
                    color: specialDay.symbol !== 'none' ? SectionColors.get(specialDay.color) : undefined,
                    userSelect: 'none'
                  }}
                >{`${specialDay.title}${suffix}`}</Typography>
              );
            })}
          </Stack>
        </Stack>
      </DayInfo>
      <DisplayableContentBox
        sx={{ flexGrow: 1, flexBasis: 0, flexShrink: 0 }}
        itemSpacing={4}
        minItemHeight={taskIconSize}
        minItemWidth={taskIconSize}
        maxItemHeight={taskIconSize}
        maxItemWidth={taskIconSize}
        iconSize={taskIconSize}
        representationKind="icon"
        viewModel={viewModel.contents}
        canAddTask={false}
        alwaysDisplayActionButton={true}
        padding={{
          left: theme.studyo.baseThicknessValue,
          bottom: theme.studyo.baseThicknessValue,
          right: theme.studyo.baseThicknessValue,
          top: 0
        }}
        contentListKind="school-day"
      />
    </Stack>
  );
});
