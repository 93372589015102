import { Location, matchPath } from 'react-router';

export interface RouteParameterInfo {
  /**
   * The name of the parameter. See `RouteParamNames` for the list of possible values.
   */
  name: string;
  /**
   * The value for the parameter.
   */
  value: string;
}

export interface ReactRouterRouteService {
  /**
   * Takes a route template and return if the current route matches it.
   * @param routeTemplate The route template to test the current location against.
   * @param exact `True` if the match should be successful only if the route is the template or `false` to
   * include sub-paths.
   * @param location The location to test. If undefined, will revert to the current location in the history.
   */
  isRouteActive: (routeTemplate: string, exact: boolean, location: Location) => boolean;

  /**
   *
   * Takes a route template and return a new location object to use with the navigation.
   * @param routeTemplate The route template to use
   * @param routeParameters The list of parameters (names and values) to replace in the route template.
   * @returns Returns a `LocationDescriptorObject` built using the current location and the resolved
   * route template as the pathname.
   */
  resolveLocation: (routeTemplate: string, routeParameters?: RouteParameterInfo[]) => string;

  /**
   * Returns a display name for the route. It will try to match against a template.
   * @param routeTemplate The route template to search the name for. Use `RouteTemplates` to provide a value.
   * @param routesStrings The list of localized route names
   * @returns The name of the route if found. Else, return `undefined`.
   */
  getRouteName: (routeTemplate: string, routesStrings: Record<string, string>) => string | undefined;
  /**
   * Returns the location descriptor object defined in the state, if any.
   */
}

export interface RouteNameInfo {
  /**
   * A template for the route. Use `RouteTemplates` to provide a value.
   */
  routeTemplate: string;
  /**
   * The key in the localization resource file
   */
  localizationKey: string;
}

export class AppReactRouterRouteService implements ReactRouterRouteService {
  constructor(private readonly routeNames?: RouteNameInfo[]) {}

  isRouteActive(routeTemplate: string, exact: boolean, location: Location): boolean {
    // Localhost issue
    if (routeTemplate == null) {
      return false;
    }

    const matchedPath = matchPath(
      {
        path: routeTemplate,
        end: exact,
        caseSensitive: false
      },
      location?.pathname ?? ''
    );

    return matchedPath != null;
  }

  resolveLocation(routeTemplate: string, routeParameters?: RouteParameterInfo[]): string {
    let pathname = routeTemplate;

    if (routeParameters != null) {
      routeParameters.forEach((paramInfo) => (pathname = pathname.replace(`:${paramInfo.name}`, paramInfo.value)));
    }

    return pathname;
  }

  getRouteName(route: string, routesStrings: Record<string, string>): string | undefined {
    if (this.routeNames == null) {
      return undefined;
    }

    const foundRoute = this.routeNames.find((r) => {
      const matchedPath = matchPath(
        {
          path: r.routeTemplate,
          end: true,
          caseSensitive: false
        },
        route
      );
      return matchedPath != null;
    });

    if (foundRoute != null) {
      return routesStrings[foundRoute.localizationKey];
    }

    return undefined;
  }
}
