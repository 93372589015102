import { createBrowserRouter, Navigate } from 'react-router';
import { App } from './App.tsx';
import { AgendaScreenRoute, PrivateRoute } from './components';
import {
  AgendaDailyScreen,
  AgendaLayout,
  AgendaPlannerScreen,
  AgendaTimelineScreen,
  AgendaWeeklyScreen,
  AgendaWorkloadScreen,
  AgendaYearlyScreen,
  ConfigCreationScreen,
  LandingScreen,
  LearnAboutTodayScreen,
  LoginScreen,
  LogoutScreen,
  NotFoundScreen,
  ParentAccessRedirectScreen,
  SuccessScreen,
  UseCodeScreen
} from './screens';
import { AgendaMonthlyScreen } from './screens/authenticated/agenda/AgendaMonthlyScreen.tsx';
import { AgendaPeriodsScreen } from './screens/authenticated/agenda/AgendaPeriodsScreen.tsx';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'login', element: <LoginScreen sx={{ flex: 1 }} /> },
      { path: 'logout', element: <LogoutScreen sx={{ flex: 1 }} /> },
      /* No need for a dedicated redirects layout page */
      {
        path: 'r',
        children: [
          { path: 'pa/:configId/a/:accountId', element: <ParentAccessRedirectScreen sx={{ flex: 1 }} /> },
          { path: 'success', element: <SuccessScreen sx={{ flex: 1 }} /> }
        ]
      },
      {
        path: '',
        element: <PrivateRoute />,
        children: [
          { path: '', element: <LandingScreen sx={{ flex: 1 }} /> },
          {
            path: 'c/:configId/a/:accountId/agenda',
            element: <AgendaLayout sx={{ width: '100%', height: '100%' }} />,
            children: [
              {
                path: 'daily',
                element: (
                  <AgendaScreenRoute>
                    <AgendaDailyScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'weekly',
                element: (
                  <AgendaScreenRoute supportsSmallScreen={false}>
                    <AgendaWeeklyScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'monthly',
                element: (
                  <AgendaScreenRoute supportsSmallScreen={false}>
                    <AgendaMonthlyScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'yearly',
                element: (
                  <AgendaScreenRoute supportsSmallScreen={false}>
                    <AgendaYearlyScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'timeline',
                element: (
                  <AgendaScreenRoute>
                    <AgendaTimelineScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'planner',
                element: (
                  <AgendaScreenRoute supportsSmallScreen={false}>
                    <AgendaPlannerScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'workload',
                element: (
                  <AgendaScreenRoute supportsSmallScreen={false}>
                    <AgendaWorkloadScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: 'periods',
                element: (
                  <AgendaScreenRoute supportsSmallScreen={false}>
                    <AgendaPeriodsScreen sx={{ width: '100%', height: '100%' }} />
                  </AgendaScreenRoute>
                )
              },
              {
                path: '',
                element: <Navigate to="daily" replace />
              },
              {
                path: '*',
                element: <NotFoundScreen sx={{ width: '100%', height: '100%', zIndex: 2 }} />
              }
            ]
          },
          {
            path: 'onboarding',
            children: [
              {
                path: 'createconfig/:isInitialConfig',
                element: <ConfigCreationScreen sx={{ width: '100%', height: '100%' }} />
              },
              {
                path: 'today',
                element: <LearnAboutTodayScreen sx={{ width: '100%', height: '100%' }} />
              },
              {
                path: 'usecode/:isInitialConfig',
                element: <UseCodeScreen sx={{ width: '100%', height: '100%' }} />
              }
            ]
          },
          { path: '*', element: <NotFoundScreen sx={{ width: '100%', height: '100%' }} /> }
        ]
      },
      { path: '*', element: <Navigate to="" replace /> }
    ]
  }
]);
