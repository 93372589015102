import { CourseOccurrence } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentAttachmentModel, ContentDefinitionModel, EditableContentDefinition } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { DialogCancelled, ModalService } from '@shared/services';
import { NavigateFunctionAsync } from '@shared/utils';
import { ReactRouterRouteService } from '@shared/web/services';
import {
  WorkloadImpactDetailsScreen,
  WorkloadImpactDetailsScreenProps
} from '@studyo/screens/authenticated/contents/WorkloadImpactDetailsScreen.tsx';
import { WorkloadInfo } from '@studyo/viewmodels/index.ts';
import { Location } from 'react-router';
import { RouteParamNames, RouteTemplates } from '../Routes.ts';
import {
  AskNameScreen,
  AskNameScreenProps,
  ConfigLinkListScreen,
  ConfigLinkListScreenProps,
  ContentAttachmentAddScreen,
  ContentAttachmentAddScreenProps,
  ContentDistributeScreen,
  ContentDistributeScreenProps,
  ContentPublishStudentSelectionScreen,
  ContentPublishStudentSelectionScreenProps,
  ContentRepeatScreen,
  ContentRepeatScreenProps,
  CourseSelectionScreen,
  CourseSelectionScreenProps,
  DailyOptionMenuScreen,
  DailyOptionMenuScreenProps,
  DisplayableContentListScreen,
  DisplayableContentListScreenProps,
  GearMenuShareScreen,
  GearMenuShareScreenProps,
  ImpersonateStudentSelectionScreen,
  ImpersonateStudentSelectionScreenProps,
  LinkedTasksDeleteScreen,
  LinkedTasksDeleteScreenProps,
  LinkedTasksPublishScreen,
  LinkedTasksPublishScreenProps,
  MonthlyFiltersOptionsScreen,
  MonthlyFiltersOptionsScreenProps,
  MonthlyOptionMenuScreen,
  MonthlyOptionMenuScreenProps,
  MonthlySectionFilterScreen,
  MonthlySectionFilterScreenProps,
  NoteEditScreen,
  NoteEditScreenProps,
  OtherProfilesListScreen,
  OtherProfilesListScreenProps,
  PeriodOptionMenuScreen,
  PeriodOptionMenuScreenProps,
  PeriodPrioritySelectorScreen,
  PeriodPrioritySelectorScreenProps,
  PeriodSectionsFilterScreen,
  PeriodSectionsFilterScreenProps,
  PeriodTasksFilterOptionsScreen,
  PeriodTasksFilterOptionsScreenProps,
  PlannerFilterOptionsScreen,
  PlannerFilterOptionsScreenProps,
  PlannerOptionMenuScreen,
  PlannerOptionMenuScreenProps,
  PlannerPeriodEditScreen,
  PlannerPeriodEditScreenProps,
  PlannerPeriodInfoScreen,
  PlannerPeriodInfoScreenProps,
  PlannerPeriodMoveContentScreen,
  PlannerPeriodMoveContentScreenProps,
  PlannerSectionFilterScreen,
  PlannerSectionFilterScreenProps,
  PlannerSectionInfoScreen,
  PlannerSectionInfoScreenProps,
  PreferencesScreen,
  PreferencesScreenProps,
  ProfileEditScreen,
  ProfileEditScreenProps,
  ProfileMenuScreen,
  ProfileMenuScreenProps,
  SchoolDayContentListScreen,
  SchoolDayContentListScreenProps,
  TaskAttachmentListScreen,
  TaskAttachmentListScreenProps,
  TaskEditScreen,
  TaskEditScreenProps,
  TaskInfoScreen,
  TaskInfoScreenProps,
  TaskStepsOrderScreen,
  TaskStepsOrderScreenProps,
  TimelineFiltersOptionsScreen,
  TimelineFiltersOptionsScreenProps,
  TimelineOptionMenuScreen,
  TimelineOptionMenuScreenProps,
  WeeklyOptionMenuScreen,
  WeeklyOptionMenuScreenProps
} from '../screens';
import { UrlUtils } from '../utils/UrlUtils.ts';
import { AgendaScreenKind, NavigationService } from './NavigationService';

export class WebNavigationService implements NavigationService {
  constructor(
    private readonly reactRouterRouteServiceResolver: () => ReactRouterRouteService,
    private readonly modalServiceResolver: () => ModalService
  ) {}

  getAgendaScreenLocation(kind: AgendaScreenKind, configId: string, accountId: string): string {
    const pathTemplate = RouteTemplates.config.account.agenda[kind];
    return this.reactRouterRouteServiceResolver().resolveLocation(pathTemplate, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.accountId, value: accountId }
    ]);
  }

  /* Daily */
  navigateToDailyOptionMenuModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, DailyOptionMenuScreenProps>(DailyOptionMenuScreen, {});
  }

  /* Weekly */
  navigateToWeeklyOptionMenuModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, WeeklyOptionMenuScreenProps>(WeeklyOptionMenuScreen, {});
  }

  /* Monthly */

  navigateToMonthlyFilterOptionsModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, MonthlyFiltersOptionsScreenProps>(
      MonthlyFiltersOptionsScreen,
      {}
    );
  }

  navigateToMonthlyOptionMenuModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, MonthlyOptionMenuScreenProps>(MonthlyOptionMenuScreen, {});
  }

  navigateToMonthlySectionFilterModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, MonthlySectionFilterScreenProps>(MonthlySectionFilterScreen, {});
  }

  /* Planner */

  navigateToPlannerPeriodInfoModal(day: Day, periodTag: string, sectionId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerPeriodInfoScreenProps>(PlannerPeriodInfoScreen, {
      day,
      periodTag,
      sectionId
    });
  }

  navigateToPlannerPeriodContentMoveModal(
    day: Day,
    periodTag: string,
    sectionId: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerPeriodMoveContentScreenProps>(
      PlannerPeriodMoveContentScreen,
      {
        day,
        periodTag,
        sectionId
      }
    );
  }

  navigateToPlannerPeriodEditModal(courseOccurrence: CourseOccurrence): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerPeriodEditScreenProps>(PlannerPeriodEditScreen, {
      courseOccurrence
    });
  }

  navigateToPlannerOptionMenuModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerOptionMenuScreenProps>(PlannerOptionMenuScreen, {});
  }

  navigateToPlannerSectionFilterModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerSectionFilterScreenProps>(PlannerSectionFilterScreen, {});
  }

  navigateToPlannerFilterOptionsModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerFilterOptionsScreenProps>(PlannerFilterOptionsScreen, {});
  }

  navigateToPlannerSectionInfo(sectionId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PlannerSectionInfoScreenProps>(PlannerSectionInfoScreen, {
      sectionId
    });
  }

  /* Periods */

  navigateToPeriodOptionMenuModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PeriodOptionMenuScreenProps>(PeriodOptionMenuScreen, {});
  }

  navigateToPeriodSectionsFiltersModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PeriodSectionsFilterScreenProps>(PeriodSectionsFilterScreen, {});
  }

  navigateToPeriodTasksFiltersModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PeriodTasksFilterOptionsScreenProps>(
      PeriodTasksFilterOptionsScreen,
      {}
    );
  }

  /* Timeline */

  navigateToTimelineFilterOptionsModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, TimelineFiltersOptionsScreenProps>(
      TimelineFiltersOptionsScreen,
      {}
    );
  }

  navigateToTimelineOptionMenuModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, TimelineOptionMenuScreenProps>(TimelineOptionMenuScreen, {});
  }

  /* Contents */

  navigateToDisplayableContentListModal(
    day: Day,
    periodTag: string | undefined,
    sectionId: string | undefined,
    contentIds: string[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, DisplayableContentListScreenProps>(
      DisplayableContentListScreen,
      {
        day,
        periodTag,
        sectionId,
        contentIds
      }
    );
  }

  navigateToSchoolDayContentListModal(day: Day): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, SchoolDayContentListScreenProps>(SchoolDayContentListScreen, {
      day
    });
  }

  navigateToTaskInfoModal(contentId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, TaskInfoScreenProps>(TaskInfoScreen, {
      contentId
    });
  }

  navigateToTaskEditModal(
    contentId: string | undefined,
    day: Day | undefined,
    periodTag: string | undefined,
    sectionId: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ): Promise<string | DialogCancelled> {
    return this.modalServiceResolver().showModal<string, TaskEditScreenProps>(TaskEditScreen, {
      contentId,
      day,
      periodTag,
      sectionId
    });
  }

  navigateToTaskStepsOrderModal(
    content: EditableContentDefinition,
    selectedSection: SectionModel | undefined,
    onSave: (content: EditableContentDefinition) => void
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, TaskStepsOrderScreenProps>(TaskStepsOrderScreen, {
      content,
      selectedSection,
      onSave
    });
  }

  navigateToNoteEditModal(
    day: Day,
    periodTag: string,
    sectionId: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ): Promise<string | DialogCancelled> {
    return this.modalServiceResolver().showModal<string, NoteEditScreenProps>(NoteEditScreen, {
      day,
      periodTag,
      sectionId
    });
  }

  navigateToContentPublishStudentSelection(content: () => ContentDefinitionModel): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ContentPublishStudentSelectionScreenProps>(
      ContentPublishStudentSelectionScreen,
      { content }
    );
  }

  navigateToContentPublishStudentSelectionModal(contentId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ContentPublishStudentSelectionScreenProps>(
      ContentPublishStudentSelectionScreen,
      { content: contentId }
    );
  }

  navigateToContentRepeat(contentId: string): Promise<ContentDefinitionModel | DialogCancelled> {
    return this.modalServiceResolver().showModal<ContentDefinitionModel, ContentRepeatScreenProps>(
      ContentRepeatScreen,
      {
        contentId
      }
    );
  }

  navigateToContentDistribute(contentId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ContentDistributeScreenProps>(ContentDistributeScreen, {
      contentId
    });
  }

  navigateToAttachmentList(
    getAttachments: () => ContentAttachmentModel[],
    deleteAttachment: (attachment: ContentAttachmentModel) => Promise<void>,
    addAttachment: (attachment: ContentAttachmentModel) => void,
    isSlaveContent: boolean,
    sectionId: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, TaskAttachmentListScreenProps>(TaskAttachmentListScreen, {
      getAttachments,
      deleteAttachment,
      addAttachment,
      isSlaveContent,
      sectionId
    });
  }

  navigateToAttachmentAdd(
    addAttachment: (attachment: ContentAttachmentModel) => Promise<void>
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ContentAttachmentAddScreenProps>(ContentAttachmentAddScreen, {
      addAttachment
    });
  }

  navigateToLinkedTasksDelete(originatingTaskId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, LinkedTasksDeleteScreenProps>(LinkedTasksDeleteScreen, {
      contentId: originatingTaskId
    });
  }

  navigateToLinkedTasksPublish(originatingTaskId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, LinkedTasksPublishScreenProps>(LinkedTasksPublishScreen, {
      contentId: originatingTaskId
    });
  }

  navigateToWorkloadImpactDetails(
    workloadInfos: WorkloadInfo[],
    sectionsById: Map<string, SectionModel>,
    accountsById: Map<string, AccountModel>,
    isInformativeOnly: boolean
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, WorkloadImpactDetailsScreenProps>(WorkloadImpactDetailsScreen, {
      workloadInfos,
      sectionsById,
      accountsById,
      isInformativeOnly
    });
  }

  /* Settings */

  navigateToProfileMenu(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ProfileMenuScreenProps>(ProfileMenuScreen, {});
  }

  navigateToOtherProfilesList(): Promise<void | DialogCancelled> {
    // This redirection is required because other platforms handle navigation differently depending on
    // whether we are navigating from an existing dialog or not.
    return this.navigateToOtherProfilesListModal();
  }

  navigateToOtherProfilesListModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, OtherProfilesListScreenProps>(OtherProfilesListScreen, {});
  }

  navigateToProfileEdit(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ProfileEditScreenProps>(ProfileEditScreen, {});
  }

  navigateToGearMenuShare(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, GearMenuShareScreenProps>(GearMenuShareScreen, {});
  }

  navigateToCourseSelection(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, CourseSelectionScreenProps>(CourseSelectionScreen, {});
  }

  navigateToPreferences(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PreferencesScreenProps>(PreferencesScreen, {});
  }

  navigateToAskNameModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, AskNameScreenProps>(AskNameScreen, {});
  }

  navigateToConfigLinkListModal(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ConfigLinkListScreenProps>(ConfigLinkListScreen, {});
  }

  navigateToImpersonateStudentSelection(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, ImpersonateStudentSelectionScreenProps>(
      ImpersonateStudentSelectionScreen,
      {}
    );
  }

  showIntercomMessenger() {
    if (window.Intercom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  }

  /* Utils */

  navigateToPeriodPrioritySelector(periodTag: string, day: Day): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal<void, PeriodPrioritySelectorScreenProps>(
      PeriodPrioritySelectorScreen,
      { periodTag, day }
    );
  }

  /* Others */

  navigateToLogin(location: Location, navigate: NavigateFunctionAsync, removeReferrer?: boolean): Promise<void> {
    if (removeReferrer) {
      // When logging out, we navigate to the login page. However, we need to reset the location state,
      // or otherwise we are taken back to the previous url (pointing to old Ids) when logging back in.
      //
      // See https://app.clubhouse.io/studyodev/story/10340/when-disconnecting-then-reconnecting-to-another-user-we-re-redirected-to-the-previous-url
      location.state = {};
    }

    return navigate(this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.login));
  }

  navigateToUseCode(isInitialConfig: boolean, navigate: NavigateFunctionAsync): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.onboarding.useCode, [
      { name: RouteParamNames.isInitialConfig, value: isInitialConfig ? '1' : '0' }
    ]);
    return navigate(location);
  }

  navigateToCreateConfig(isInitialConfig: boolean, navigate: NavigateFunctionAsync): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.onboarding.configCreation, [
      { name: RouteParamNames.isInitialConfig, value: isInitialConfig ? '1' : '0' }
    ]);
    return navigate(location);
  }

  navigateToLearnAboutToday(navigate: NavigateFunctionAsync): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.onboarding.aboutToday);
    return navigate(location);
  }

  // This is used for account switching, to re-load the same page with new account.
  navigateToSamePage(
    configId: string,
    accountId: string,
    location: Location,
    navigate: NavigateFunctionAsync
  ): Promise<void> {
    const routeService = this.reactRouterRouteServiceResolver();

    const currentPath = Object.keys(RouteTemplates.config.account.agenda)
      .map((key) => RouteTemplates.config.account.agenda[key] as string)
      .find((path) => routeService.isRouteActive(path, true, location));

    if (currentPath != null) {
      const location = this.reactRouterRouteServiceResolver().resolveLocation(currentPath, [
        { name: RouteParamNames.configId, value: configId },
        { name: RouteParamNames.accountId, value: accountId }
      ]);

      return navigate(location);
    } else {
      const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.landing, [
        { name: RouteParamNames.configId, value: configId },
        { name: RouteParamNames.accountId, value: accountId }
      ]);
      return navigate(location);
    }
  }

  navigateToCheckout(url: string): Promise<void> {
    window.location.assign(url);
    return Promise.resolve();
  }

  closeAllModals(): Promise<void> {
    this.modalServiceResolver().closeAllModals();
    return Promise.resolve();
  }

  redirectToLogin(navigate: NavigateFunctionAsync): Promise<void> {
    const location: Location = {
      pathname: this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.login),
      key: '',
      state: undefined,
      hash: '',
      search: ''
    };

    this.removeAuthenticationInformation(location);
    return navigate(location, { replace: true });
  }

  completeLogin(location: Location, navigate: NavigateFunctionAsync, referrer?: string): Promise<void> {
    let newLocation: Location = { pathname: RouteTemplates.landing, search: '', hash: '', key: '', state: undefined };

    if (referrer != null) {
      const queryParamsIndex = referrer.indexOf('?');

      // NOTE:  We need to split the query params from the hostname to properly create a location object.
      const pathname = referrer.substring(0, queryParamsIndex > -1 ? queryParamsIndex : undefined);
      const search = queryParamsIndex > -1 ? referrer.substring(referrer.indexOf('?')) : undefined;

      newLocation = { ...location, pathname, search: search ?? '' };
    }

    this.removeAuthenticationInformation(newLocation);
    return navigate(newLocation, { replace: true });
  }

  async redirectToReferrerOrLanding(
    location: Location,
    navigate: NavigateFunctionAsync,
    referrer?: string
  ): Promise<void> {
    const reactRouterRouteService = this.reactRouterRouteServiceResolver();

    if (referrer != null) {
      return navigate(referrer, { replace: true });
    } else {
      const ref = this.extractReferrer(location);
      return navigate(ref ?? reactRouterRouteService.resolveLocation(RouteTemplates.landing), { replace: true });
    }
  }

  extractReferrer(location: Location): string | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const referrer = location.state?.referrer;

    if (referrer == null) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { pathname, search } = referrer;

    if (pathname == null) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return pathname + search;
  }

  private removeAuthenticationInformation(location: Location) {
    // Ensure we remove the authentication information (contained in the `hash` property)
    location.hash = '';

    // Remove the `completion`
    location.search = UrlUtils.removeQueryParamValue(location.search ?? '', 'completion');
  }
}
