import { Divider, List, ListItemButton, ListItemText, SxProps } from '@mui/material';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useStudyoServices } from '../../UseStudyoServicesHook';
import { ListSection, OtherProfilesListProfileRow, TintedListItemIcon } from '../../components';
import { OtherProfilesListViewModel } from '../../viewmodels';

export interface OtherProfilesListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: OtherProfilesListViewModel;
}

export const OtherProfilesListView = observer((props: OtherProfilesListViewProps) => {
  const { imageService, localizationService } = useStudyoServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.studyo.agenda.setting.otherProfilesList;
  const navigate = useNavigateAsync();

  return (
    <List sx={{ ...sx, overflow: 'auto' }} className={className}>
      <ListSection>
        <ListItemButton onClick={() => viewModel.useCode(navigate)}>
          <TintedListItemIcon icon={imageService.studyoImages.settings.profileMenu.useSchoolCode} isTextPrimaryColor />
          <ListItemText primary={strings.useCode} />
        </ListItemButton>
      </ListSection>

      {viewModel.sections.map((section) => (
        <ListSection key={`other-profiles-section-${section.title}`} title={section.title}>
          {section.profiles.map((profile, i, values) => (
            <Fragment key={`other-profiles-section-${section.title}-profile-${i}`}>
              <OtherProfilesListProfileRow viewModel={profile} />

              {i < values.length - 1 && <Divider />}
            </Fragment>
          ))}
        </ListSection>
      ))}
    </List>
  );
});
