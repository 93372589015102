export const StudyoSettingsKeys = {
  //
  // Generic
  //

  announcementDateToday: 'announcement-date-today',
  newTaskDueNextPeriod: 'new-task-due-next-period',

  //
  // Day
  //

  dailyContentDisplayKind: 'daily-content-display-kind',
  dailyPeriodHeight: 'daily-period-height',
  dailyContentPresentationKind: 'daily-content-representation-kind',
  dailyShowAssignmentDay: 'daily-show-assignment-day',

  //
  // Week
  //

  weeklyContentDisplayKind: 'weekly-content-display-kind',
  weeklyPeriodHeight: 'weekly-period-height',
  weeklyDisplayWeekends: 'weekly-display-weekends',

  //
  // Month
  //

  monthlySectionFilters: 'monthly-section-filters',
  monthlyContentFilters: 'monthly-content-filters',
  monthlyShowNotes: 'monthly-show-notes',
  monthlyShowTasks: 'monthly-show-tasks',

  //
  // Timeline
  //

  timelineContentFilters: 'timeline-content-filters',
  timelineCellSize: 'timeline-cell-size',
  timelineShowAssignmentDay: 'timeline-show-assignment-day',
  timelineLimitWeeks: 'timeline-limit-weeks',

  //
  // Planner
  //

  plannerPeriodHeight: 'planner-period-height',
  plannerSectionFilters: 'planner-section-filters',
  plannerDisplayWeekends: 'planner-display-weekends',
  plannerContentFilters: 'planner-content-filters',
  plannerShowAssignmentDay: 'planner-show-assignment-day',

  //
  // Periods
  //

  periodSectionFilters: 'period-section-filters',
  periodContentFilters: 'period-content-filters',
  periodShowAssignmentDay: 'period-show-assignment-day',

  //
  // UI
  //

  backgroundOpacity: 'ui-background-opacity',
  backgroundOnlyOnHeader: 'ui-background-only-on-header'
};
