import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { AccountSummary } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { NavigateFunctionAsync } from '@shared/utils';
import { computed, makeObservable } from 'mobx';
import { Location } from 'react-router';
import { AccountService, NavigationService } from '../../../services';

export interface ProfileMenuProfileViewModel {
  readonly firstName: string;
  readonly lastName: string;
  readonly displayName: string;
  readonly schoolName: string;
  readonly schoolTitle: string;
  readonly color: Color | undefined;
  readonly isImpersonating: boolean;

  selectProfile(location: Location, navigate: NavigateFunctionAsync): Promise<void>;
}

export class AppProfileMenuProfileViewModel implements ProfileMenuProfileViewModel {
  constructor(
    protected readonly _accountSummary: AccountSummary,
    protected readonly _childAccountSummary: AccountSummary | undefined,
    protected readonly _accountService: AccountService,
    protected readonly _navigationService: NavigationService,
    private readonly _onSwitchProfile: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get firstName(): string {
    // Do not use private first name when viewing a child's profile.
    return this._childAccountSummary == null
      ? this._accountSummary.profile.privateFirstName || this._accountSummary.firstName
      : this._childAccountSummary.firstName;
  }

  @computed
  get lastName(): string {
    // Do not use private last name when viewing a child's profile.
    return this._childAccountSummary == null
      ? this._accountSummary.profile.privateLastName || this._accountSummary.lastName
      : this._childAccountSummary.lastName;
  }

  @computed
  get displayName(): string {
    return AccountUtils.getDisplayFirstLastName(
      this._childAccountSummary ?? this._accountSummary,
      '',
      this._childAccountSummary == null
    );
  }

  @computed
  get schoolName(): string {
    return this._accountSummary.configurationSummary?.schoolName ?? 'n/a';
  }

  @computed
  get schoolTitle(): string {
    return this._accountSummary.configurationSummary != undefined
      ? SchoolYearConfigurationUtils.displayTitle(this._accountSummary.configurationSummary)
      : 'n/a';
  }

  @computed
  get color(): Color | undefined {
    return this._childAccountSummary?.profile.avatarColor ?? this._accountSummary.profile.avatarColor;
  }

  @computed
  get isImpersonating() {
    return this._accountSummary.role === 'teacher' && this._childAccountSummary != null;
  }

  async selectProfile(location: Location, navigate: NavigateFunctionAsync) {
    const accountId = this._childAccountSummary?.id ?? this._accountSummary.id;

    await this._accountService.setCurrentDisplayedAccount(accountId);
    this._onSwitchProfile();
    await this._navigationService.closeAllModals();
    await this._navigationService.navigateToSamePage(this._accountSummary.configId, accountId, location, navigate);
  }
}
