import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { chain } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Location, useLocation } from 'react-router';
import { RouteNames } from '../Routes';
import { useStudyoServices } from '../UseStudyoServicesHook';

const TitleSeparator = ' | ';

export const BrowserHistoryListener = observer(() => {
  const { accountService, localizationService, reactRouterRouteService } = useStudyoServices();
  const location = useLocation();

  const performUpdates = (location: Location) => {
    updateTabTitle(location);
  };

  const updateTabTitle = (location: Location) => {
    const studyoStrings = localizationService.localizedStrings.studyo;
    const routeStrings = studyoStrings.routes;

    const currentAccount = accountService.currentDisplayedAccount;

    const routeName = chain(RouteNames)
      .filter((r) => reactRouterRouteService.isRouteActive(r.routeTemplate, true, location))
      .map((r) => reactRouterRouteService.getRouteName(r.routeTemplate, { ...routeStrings }))
      .head()
      .value();

    let title = studyoStrings.applicationName;

    if (routeName != null) {
      title += `${TitleSeparator}${routeName}`;
    }

    if (currentAccount != null) {
      title += `${TitleSeparator}${AccountUtils.getDisplayFirstLastName(currentAccount)}`;

      if (currentAccount.configurationSummary != null) {
        title += `${TitleSeparator}${
          currentAccount.configurationSummary.schoolName
        } (${SchoolYearConfigurationUtils.displayTitle(currentAccount.configurationSummary)})`;
      }
    }

    window.document.title = title;
  };

  useEffect(() => {
    performUpdates(location);
  }, [location]);

  return null;
});
