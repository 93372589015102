import { Box, CardActionArea, SxProps, useTheme } from '@mui/material';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { ContentNoteBoxViewModel } from '../../viewmodels';
import { DisplayableContent } from './DisplayableContent';

export interface ContentNoteBoxProps {
  sx?: SxProps;
  className?: string;
  viewModel: ContentNoteBoxViewModel;
  hasTransparency?: boolean;
}

export const ContentNoteBox = observer((props: ContentNoteBoxProps) => {
  const { sx = [], className, viewModel, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();
  const note = viewModel.note;

  const tapOnNote = () => {
    void viewModel.openNoteEdit();
  };

  return (
    <CardActionArea
      sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      className={className}
      onClick={tapOnNote}
      component="div"
    >
      {note != null && (
        <DisplayableContent
          sx={{ height: '100%', width: '100%' }}
          viewModel={note}
          representation={'inline'}
          tapHandler={tapOnNote}
          hasTransparency={hasTransparency}
        />
      )}

      {note == null && (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            backgroundColor: withTransparencyWhen(
              hasTransparency,
              theme.studyo.contents.icons.notesBackgroundColor,
              0.75 * backgroundOpacity
            )
          }}
        />
      )}
    </CardActionArea>
  );
});
