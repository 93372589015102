import { InfoOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { withTransparencyWhen } from '@studyo/theme';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook';
import { observer } from 'mobx-react-lite';
import { PlannerSectionHeaderViewModel } from '../../../viewmodels';

export interface PlannerSectionHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerSectionHeaderViewModel;
  hasTransparency?: boolean;
}

export const PlannerSectionHeader = observer((props: PlannerSectionHeaderProps) => {
  const { viewModel, sx = [], className, hasTransparency = false } = props;
  const { backgroundOpacity } = useBackgroundImage();
  const theme = useTheme();

  let backgroundColor = SectionColors.get(viewModel.color);
  const hasBackgroundColor = backgroundColor != null;
  backgroundColor = withTransparencyWhen(
    hasTransparency,
    backgroundColor ?? theme.studyo.periods.freePeriodColor,
    backgroundOpacity
  );

  const elementsColor = hasBackgroundColor
    ? theme.studyo.periods.periodWithSectionTextColor
    : theme.studyo.periods.freePeriodTextColor;

  return (
    <Box
      className={className}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
        backgroundColor,
        color: elementsColor,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Stack
        spacing={0.5}
        sx={{
          p: 0.5
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            color: 'inherit',
            textAlign: 'center'
          }}
        >
          {viewModel.sectionTitle}
        </Typography>

        {viewModel.sectionNumber != null && viewModel.sectionNumber.length > 0 && (
          <Typography
            variant="body2"
            sx={{
              color: 'inherit',
              textAlign: 'center'
            }}
          >
            {viewModel.sectionNumber}
          </Typography>
        )}

        {viewModel.canDisplaySectionInfo && (
          <IconButton
            size="medium"
            onClick={() => viewModel.displaySectionInfo()}
            color="inherit"
            sx={{ alignSelf: 'center' }}
          >
            <InfoOutlined fontSize="medium" />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
});
