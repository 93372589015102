import { AuthorizationRole } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, useLocation } from 'react-router';
import { RouteTemplates } from '../Routes';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { ForbiddenScreen } from '../screens';

export interface PrivateRouteProps {
  authorizationRoles?: AuthorizationRole[];
}

export const PrivateRoute = observer((props: PrivateRouteProps) => {
  const { accountService, reactRouterRouteService } = useStudyoServices();
  const { authorizationRoles } = props;
  const location = useLocation();

  const isLoggedIn = accountService.isLoggedIn;

  if (!isLoggedIn) {
    const loginLocation = reactRouterRouteService.resolveLocation(RouteTemplates.login);
    return <Navigate to={loginLocation} replace state={{ referrer: location }} />;
  }

  const isAuthorized = authorizationRoles == null || accountService.isAllowed(authorizationRoles);

  if (!isAuthorized) {
    return <ForbiddenScreen sx={{ flex: 1 }} />;
  }

  return <Outlet />;
});
