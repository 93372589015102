import { Box, Stack, SxProps, useTheme } from '@mui/material';
import { AutoSizer } from '@shared/components/layout';
import { SchoolDay } from '@shared/models/calendar';
import {
  HorizontalPagedView,
  HorizontalPagedViewHandle
} from '@shared/rxp/horizontal-paged-view/HorizontalPagedView.tsx';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { StudyoThemeService } from '../../services';
import { SchoolDayPickerViewModel } from '../../viewmodels';
import { YearMonth } from '../agenda';

export interface SchoolDayPickerProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolDayPickerViewModel;
  onSelection: (schoolDay: SchoolDay) => void;
  backgroundColor?: string;
  themeService?: StudyoThemeService;
}

export const SchoolDayPicker = observer((props: SchoolDayPickerProps) => {
  const { sx = [], className, viewModel, backgroundColor, onSelection } = props;
  const pageViewRef = useRef<HorizontalPagedViewHandle | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const disposer = reaction(
      () => viewModel.currentMonthIndex,
      (index) => pageViewRef.current?.scrollToPage(index, true, false)
    );
    return () => disposer();
  }, [viewModel]);

  return (
    <Stack
      sx={{
        ...sx,
        backgroundColor: backgroundColor ?? theme.studyo.utils.schoolDayPicker.backgroundColor,
        position: 'relative'
      }}
      className={className}
    >
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <HorizontalPagedView
            forwardedRef={(ref) => (pageViewRef.current = ref)}
            sx={{ height, width }}
            data={viewModel.months}
            initialPage={viewModel.currentMonthIndex}
            pageWidth={width}
            displayPagingControls={true}
            renderPage={({ index }) => (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <YearMonth
                  month={viewModel.months[index]}
                  onSelection={(schoolDay) => onSelection(schoolDay)}
                  canDisplayDayInfo={false}
                />
              </Box>
            )}
          />
        )}
      </AutoSizer>
    </Stack>
  );
});
